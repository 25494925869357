/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataLookupConfig = {
    properties: {
        branches: {
            type: 'array',
            contains: {
                type: 'DataLookupBranch',
            },
            isRequired: true,
        },
        editable: {
            type: 'boolean',
        },
        creatable: {
            type: 'boolean',
        },
        defaults: {
            type: 'string',
        },
        visual: {
            type: 'DataJoinVisual',
        },
    },
} as const;
