/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiWorkflowInputUpdate = {
    description: `Rough Shape of a split data frame`,
    properties: {
        index: {
            type: 'array',
            contains: {
                properties: {
                },
            },
            isRequired: true,
        },
        columns: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        data: {
            type: 'array',
            contains: {
                type: 'array',
                contains: {
                    properties: {
                    },
                },
            },
            isRequired: true,
        },
        target: {
            type: 'ApiWorkflowInputRef',
            isRequired: true,
        },
    },
} as const;
