import gql from 'graphql-tag'
import {ApiTransactionProspect} from "~/schemas/gen"
import {Transaction_Prospects_Bool_Exp} from "~/models/graphql"




export type ApiTransactionProspectNode = Partial<ApiTransactionProspect>



export const fragApiTransactionProspectFields = gql`
fragment ApiTransactionProspectFields on transaction_prospects {
    uuid instrument_type direction created_time
    instrument {
        display_name uuid identifier_type instrument_type
    }
    request
}
`


export function transactionProspectSearchExp(rawSearch:string):Transaction_Prospects_Bool_Exp{
   return {}
}


