
import {
    Transaction_Legs,Transactions,
    Instruments,
    Transaction_Line_Items
} from "~/models/graphql"
import {ApiTransactionProspect} from "~/schemas/gen"
import {relativeNow} from "~/utils/dates"
import {CreateElement} from "vue"
import {
    lineItemActionToString,typeToString,
    directionToString
} from "~/core/transactions/render"
import {CARET} from "~/core/common/render"




export type IRenderableTransaction = Pick<Transactions,"uuid"|"transaction_id"> &
    {
        line_item: {
            action: Transaction_Line_Items["action"],
            unit_leg: {
                nominal_amount:Transaction_Legs["nominal_amount"],
                instrument:Pick<Instruments,"uuid"|"display_name"|"identifier_type"|"instrument_type">,
            }
        }
    }


/**
 * Single kine description of transaction
 */
export function describeTransaction(tgt:IRenderableTransaction,isShort:boolean=false):string {
    if(!tgt.line_item) return "???"
    let instrument =  tgt.line_item.unit_leg.instrument
    let typ = typeToString(instrument.instrument_type)
    if(isShort){
        return `${typ} ${CARET} ${instrument.display_name}`
    }
    let action =lineItemActionToString(tgt.line_item.action)
    let prefix = "";
    if(tgt.transaction_id) prefix = tgt.transaction_id + " | "
    return `${prefix}${action} ${tgt.line_item?.unit_leg?.nominal_amount ?? '???'} ${instrument.display_name} (${typ})`
}


export interface Line2Opts {
    instrument:boolean
}

export function transactionLine2Segments(tgt:IRenderableTransaction,opts?:Partial<Line2Opts>):string[]{
    let action =lineItemActionToString(tgt?.line_item?.action)
    let prefix = "";
    let instrument =  tgt.line_item?.unit_leg?.instrument
    let typ = instrument?typeToString(instrument.instrument_type):"???"
    let parts = [ action,CARET,tgt?.line_item?.unit_leg?.nominal_amount ?? "???"]
    if(opts?.instrument){
        parts.push(CARET,instrument?.display_name ?? "???")
    }
    parts.push(CARET,typ)
    return parts
}


export function transactionLine2(tgt:IRenderableTransaction, h:CreateElement,opts?:Partial<Line2Opts>):ReturnType<CreateElement>{
    let parts = transactionLine2Segments(tgt,opts)
    return  h("h6",{},parts )

}


export function describeTransactionProspect(x:Partial<ApiTransactionProspect>):string{
    let typ = (x.instrument_type?typeToString(x.instrument_type):undefined) ?? "-"
    let direction =  (x.direction?directionToString(x.direction):undefined) ?? "-"
    let date = x.created_time?relativeNow(x.created_time):"-"
    return `Prospect ${direction} ${typ} | ${date}`

}

