/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataLookupExpanded = {
    properties: {
        root: {
            type: 'one-of',
            contains: [{
                type: 'string',
                pattern: '[a-zA-Z0-9\\._\\-]+#[a-zA-Z0-9\\.\\-_]+(@[a-zA-Z0-9_]+)?',
            }, {
                properties: {
                    stage: {
                        type: 'string',
                        isRequired: true,
                    },
                    name: {
                        type: 'string',
                        isRequired: true,
                    },
                    alias: {
                        type: 'string',
                    },
                },
            }],
            isRequired: true,
        },
        primary_key: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        included: {
            type: 'array',
            contains: {
                type: 'DataResolvedField',
            },
            isRequired: true,
        },
        columns: {
            type: 'array',
            contains: {
                type: 'JoinVisualColumn',
            },
            isRequired: true,
        },
        support_columns: {
            type: 'array',
            contains: {
                type: 'JoinVisualColumn',
            },
            isRequired: true,
        },
    },
} as const;
