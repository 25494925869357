
export interface ValidationError {
    loc:string[]
    msg:string
    type:string
    ctx?:object
}

export type ValidationErrorMap = Record<string,ValidationError[]>;


export interface MapValidationErrorsOpts {
    target:ValidationErrorMap
    locTransform:(x:string[])=> string|false
}

export function stdLocTransform( path:string[]):string {
    if(path[0] == "body") path = path.slice(1);
    return path.join(".")
}

/***
 * Take a list of valuidation errors and make a map we can look them up in
 */
export function mapValidationErrors(errors:ValidationError[],opts:Partial<MapValidationErrorsOpts>={
    locTransform:stdLocTransform
}):ValidationErrorMap {

    let mp:ValidationErrorMap = opts.target || {}
    let {locTransform } = opts
    if(!errors) return mp
    for(let x of errors){
        let {loc} = x
        let key!:string|false ;
        if(locTransform) key= locTransform(loc)
        else key = loc.join(".")
        if(key === false) continue
        var tgt =  mp[key];
        if(!tgt) mp[key] = tgt =  []
        tgt.push(x)
    }
    return mp
}





export class HttpValidationError extends Error {
    errors:ValidationError[]
    constructor(message:string,errors:ValidationError[]) {
        super(message)
        this.errors=errors;
    }
    getMap(opts:Partial<MapValidationErrorsOpts> = { locTransform:stdLocTransform}){
        return mapValidationErrors(this.errors,opts)
    }

    static is(x:any):x is HttpValidationError {
        return x instanceof HttpValidationError
    }
}


export function messagesFor(errs:ValidationErrorMap|{value:ValidationErrorMap|null|undefined},
    field:string):string[]{
        if(!errs) return []
        if(errs.hasOwnProperty("value")) errs = errs.value as ValidationErrorMap;
        return (errs as ValidationErrorMap) ?.[field]?.map?.(x => x.msg)
    }


