/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ExpressionLanguage {
    SQL = 'sql',
    PYTHON = 'python',
}
