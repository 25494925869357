/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FieldTypes {
    INTEGER = 'integer',
    BIGINT = 'bigint',
    HUGEINT = 'hugeint',
    UINTEGER = 'uinteger',
    UBIGINT = 'ubigint',
    UHUGEINT = 'uhugeint',
    FLOAT32 = 'float32',
    FLOAT64 = 'float64',
    TIME = 'time',
    DATE = 'date',
    TIMESTAMP = 'timestamp',
    TIMESTAMPTZ = 'timestamptz',
    UUID = 'uuid',
    STRING = 'string',
    DECIMAL = 'decimal',
    BOOL = 'bool',
    ARRAY = 'array',
    LIST = 'list',
    MAP = 'map',
    STRUCT = 'struct',
    UNION = 'union',
    BINARY = 'binary',
    JSON = 'json',
}
