/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataLookupBranch = {
    properties: {
        inputs: {
            type: 'array',
            contains: {
                type: 'one-of',
                contains: [{
                    type: 'string',
                    pattern: '[a-zA-Z0-9\\._\\-]+#[a-zA-Z0-9\\.\\-_]+(@[a-zA-Z0-9_]+)?',
                }, {
                    properties: {
                        stage: {
                            type: 'string',
                            isRequired: true,
                        },
                        name: {
                            type: 'string',
                            isRequired: true,
                        },
                        alias: {
                            type: 'string',
                        },
                    },
                }],
            },
            isRequired: true,
        },
        through: {
            type: 'one-of',
            contains: [{
                type: 'string',
                pattern: '[a-zA-Z0-9\\._\\-]+#[a-zA-Z0-9\\.\\-_]+(@[a-zA-Z0-9_]+)?',
            }, {
                properties: {
                    stage: {
                        type: 'string',
                        isRequired: true,
                    },
                    name: {
                        type: 'string',
                        isRequired: true,
                    },
                    alias: {
                        type: 'string',
                    },
                },
            }],
        },
        fields: {
            type: 'dictionary',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        via: {
            type: 'dictionary',
            contains: {
                type: 'string',
            },
        },
        filters: {
            type: 'DataFilterConfig',
        },
        creatable: {
            type: 'boolean',
        },
        editable: {
            type: 'boolean',
        },
        defaults: {
            type: 'string',
        },
        join_type: {
            type: 'DataJoinType',
        },
        alias: {
            type: 'string',
        },
        divider: {
            type: 'boolean',
        },
    },
} as const;
