/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $JoinVisualColumn = {
    description: `Declaration of a column in a visual join`,
    properties: {
        identifier: {
            type: 'string',
            isRequired: true,
        },
        input_ref: {
            type: 'one-of',
            contains: [{
                type: 'string',
                pattern: '[a-zA-Z0-9\\._\\-]+#[a-zA-Z0-9\\.\\-_]+(@[a-zA-Z0-9_]+)?',
            }, {
                properties: {
                    stage: {
                        type: 'string',
                        isRequired: true,
                    },
                    name: {
                        type: 'string',
                        isRequired: true,
                    },
                    alias: {
                        type: 'string',
                    },
                },
            }],
        },
        label: {
            type: 'string',
        },
        renderer: {
            type: 'VisualColumnRenderer',
        },
        computed: {
            type: 'VisualComputedColumn',
        },
        group: {
            type: 'any-of',
            contains: [{
                type: 'VisualColumnGrouping',
            }, {
                type: 'boolean',
            }],
        },
        editor: {
            type: 'any-of',
            contains: [{
                type: 'boolean',
            }, {
                type: 'VisualColumnEditor',
            }],
        },
        divider: {
            type: 'boolean',
        },
        field: {
            type: 'InputField',
        },
        optional: {
            type: 'boolean',
        },
    },
} as const;
