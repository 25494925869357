
import {Component,Vue,Prop } from "nuxt-property-decorator"
import {getPercentFormatter} from "~/core/utils/fmt"
import { compactInteger } from "humanize-plus"
import {components} from "~/schemas/api"
import uiSettings from "~/utils/ui_settings"




@Component
export default class FmtNumber extends Vue {
    //Format a  numeric value that represents an instrument
    @Prop() readonly value!:number
    @Prop({default:false,type:Boolean} ) readonly compact!:boolean
    @Prop({default:""}) readonly defaultValue!:string
    @Prop({default:false,type:Boolean}) readonly percentage!:boolean
    @Prop({default:false,type:Boolean}) readonly  showSign!:boolean
    @Prop({default:false,type:Boolean}) readonly colorDirection!:boolean
    @Prop({default:2,type:Number}) readonly  decimalPlaces!:number
    @Prop({default:""}) readonly prefix!:string
    @Prop({default:""}) readonly suffix!:string


    get numberValue():number {
        if(typeof this.value == "number") return this.value
        return Number(this.value)
    }

    get isPositive():boolean {
        return this.isNumber ?(this.numberValue > 0):false
    }
    get isNegative():boolean {
        return this.isNumber ?(this.numberValue < 0):false
    }
    get isNumber():boolean {
        return  !isNaN(this.numberValue)
    }
    get fmtValue():string {
        if(!this.isNumber) {
            return this.defaultValue;
        }
        return this.rawFmtValue || this.defaultValue

    }
    get rawFmtValue():string|undefined {
        let {numberValue:value} = this
        if(!this.isNumber)  return
        if(this.percentage) return getPercentFormatter().format(value)
        if(this.compact) return compactInteger(value).toUpperCase()
        return value.toLocaleString(uiSettings.activeLocale,{
            minimumFractionDigits:this.decimalPlaces,
            maximumFractionDigits:this.decimalPlaces})

    }
    get maybePlus():string {
        if(!this.showSign) return ""
        if(this.isPositive) return "+"
        return ""
    }

}
