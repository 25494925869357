import {ExpandedSchema} from "./enrichment"
import {Schema,SchemaSet,enumIdToTitle} from "~/utils/schema_funcs"
import {
    InstrumentType,CapitalActivityType,EventType,
} from "~/schemas/gen"
import { IMPORT_ANNOTATION } from "./common"
import {
    FlatSchemaKey,
    instrumentInput, transactionInput,
    DETAILS_ANNOTATION,FLOW_ANNOTATION,
    COL_FULL_HALF_THIRD,
    ChoiceList,BUY_OR_SELL
} from "~/schemas/transaction_schemas"
import map from "lodash/map"


export const eventInput = {
    type:"InputEvent"
}

export const dateInput = {
    type:"InputDate",
    returnString:true
}

export const EVENT_TYPE_CHOICES:ChoiceList= [
    {title:"New Transaction",const:EventType.NEW_TRANSACTION },
    {title:"Capital Call",const:EventType.CAPITAL_CALL},
    {title:"Capital Distribution",const:EventType.CAPITAL_DISTRIBUTION},
    {title:"Settlement/Capital Activity",const:EventType.CAPITAL_ACTIVITY},
    {title:"Note",const:EventType.NOTE}
]

export const CAPITAL_ACTIVITY_TYPE_CHOICES:ChoiceList =map(CapitalActivityType,
    (value,k) => {
        return {title:enumIdToTitle(k),const:value};
    })


const simpleInstrument  = {
    type:"InputInstrument",
    objectMode:false
}

export const FlatNewTransactionEventData:SchemaSet = {

}

export const FlatEventDataSchema:SchemaSet = {
    nt: {type:"group",schema:FlatNewTransactionEventData}
}

export const FlatEventSchema:SchemaSet =  {
    event_type:{
        type:"select",
        label:"Event Type",
        items:EVENT_TYPE_CHOICES,
        itemText:"title",itemValue:"const",
        col:{xs:3}
    },
    parent_event_id: {
        label:"Parent Event",
        ...eventInput,
        col:COL_FULL_HALF_THIRD,
        [IMPORT_ANNOTATION]:{
            overrides:{
                label:"Parent Event"
            }
        }
    },
    identifier:{
        type:'text',
        label:"Identifier",
        hint:"Unique Identifier for the Event"
    },
    instant:{
        ...dateInput,
        label:"Instant",
        col:{xs:12, md:3}
    },
    description:{
        type:"text",
        label:"Description"
    },
    notes:{
        type:"text",
        label:"Notes",
        autoGrow:true,
        class:"compact"
    },
    advisor_note:{
        type:"text",
        class:"compact",
        autoGrow:true,
        label:"Advisor Notes"
    },
    event_data:{
        type:"group",
        schema:FlatEventDataSchema
    }
}


export const FlatNewTransactionEventSchema = {
    asset_type: { type:'select',label:"Asset/Item Type",
        hint:"What kind of thing did you Buy/Sell",
        [FLOW_ANNOTATION]:{
            overrides:{ label:"What is it" }
        },
        col:{ sm:12,md:3 }
    },
    what_id:{
        ...simpleInstrument,
        label:'Instrument / Security',
        hint:"What did you Buy or Sell",
        col:{md:6,xs:12},
        [FLOW_ANNOTATION]:{
            overrides:{
                label:"What is it?"
            }
        }
    },
    direction:{ type:"select",label:"Direction",
        hint:"Buy/Sell/Pay/Receive?",
        items:BUY_OR_SELL,
        [FLOW_ANNOTATION]:{
            overrides:{
                label:'Action'
            }
        },
        itemText:"title",itemValue:"const",
        clearable:false,
        col:{md:3,xs:12}
    },
    target_account_id:{
        type:"InputAccount",
        label:'Target Account',hint:"Related Account",
        includeDefault:true,
        col:{xs:12,sm:4}
    },
    counterparty_account_id:{
        type:"InputAccount",
        label:'Counter party Account',hint:"Counterparty Account",
        includeDefault:false,
        col:{xs:12,sm:4},
    },
    price_unit_id:{
        ...simpleInstrument,
        label:"Price Unit",
        /*outlined:true,clearable:false,*/
        filterType:InstrumentType.CURRENCY,
        col:{xs:12,md:3},
    },
    price:{
        type:"number",
        label:"Price (Per/Unit)",
        hint:"What did you pay/receive?",
        col:{xs:12,md:3},
        prefix:"$"
    },
    units:{
        type:"number",
        label:"Nominal Amount",
        hint:"Units of Asset/Item",
        col:{xs:12,sm:6,md:3}
    },
    fair_market_value: {
        type:"number",
        label:"Fair Market Price(FMV)",
        hint:"What would this cost?",
        col:{xs:12,sm:6,md:3},
        prefix:"$"
    },
    fair_market_value_unit_id: {
        ...simpleInstrument,
        label:"FMV Unit",
        filterType:InstrumentType.CURRENCY,
        hint:"What currency is ?",
        col:{xs:6,md:3}
    }
}


export const FlatCapitalCallEventSchema:SchemaSet = {
    due_date:{
        ...dateInput,
        label:"Due Date",
        hint:"When the funds are due",
        col:COL_FULL_HALF_THIRD

    },
    amount:{
        type:"number",
        label:"Call Amount",
        col:COL_FULL_HALF_THIRD
    },
    payable_unit_id: {
        label:"Currency",
        type:"InputInstrument",
        filterType:InstrumentType.CURRENCY,
        hint:"Currency",
        [DETAILS_ANNOTATION]:true,
        objectMode:false
    },
    capital_account_id:{
        type:"InputAccount",
        label:'Capital Account',hint:"Capital Account",
        includeDefault:false,
        objectMode:false,
        col:COL_FULL_HALF_THIRD,
        allowCreate:true
    }
}



export const FlatCapitalDistroEventSchema:SchemaSet = {
    expected_date:{
        ...dateInput,
        label:"Due Date",
        hint:"When the funds are due",
        col:COL_FULL_HALF_THIRD

    },
    amount:{
        type:"number",
        label:"Total Amount",
        col:COL_FULL_HALF_THIRD
    },
    income_amount:{
        type:"number",
        label:"Income Amount",
        col:COL_FULL_HALF_THIRD
    },
    return_of_capital_amount:{
        type:"number",
        label:"Returned Capital",
        col:COL_FULL_HALF_THIRD
    },
    interest_amount:{
        type:"number",
        label:"Interest Distribution",
        col:COL_FULL_HALF_THIRD
    },
    ordinary_dividend_amount:{
        type:"number",
        label:"Ordinary Dividend",
        col:COL_FULL_HALF_THIRD
    },
    qualified_dividend_amount:{
        type:"number",
        label:"Qualified Dividend",
        col:COL_FULL_HALF_THIRD
    },
    capital_gains_amount:{
        type:"number",
        label:"Capital Gains Distribution",
        col:COL_FULL_HALF_THIRD
    },
    redeemed_principal:{
        type:"number",
        label:"Quantity of Units Redeemed",
        col:COL_FULL_HALF_THIRD
    },
    payable_unit_id: {
        label:"Currency",
        type:"InputInstrument",
        filterType:InstrumentType.CURRENCY,
        hint:"Currency",
        [DETAILS_ANNOTATION]:true,
        objectMode:false
    },
    principal_unit_id: {
        label:"Principal Unit",
        type:"InputInstrument",
        filterType:InstrumentType.EQUITY,
        hint:"Equity/Principal",
        [DETAILS_ANNOTATION]:true,
        objectMode:false
    },
    principal_account_id:{
        type:"InputAccount",
        label:'Principal Account',hint:"Principal Account",
        includeDefault:false,
        objectMode:false,
        col:COL_FULL_HALF_THIRD,
        allowCreate:true
    }
}


export const FlatCapitalActivityEventSchema:SchemaSet = {

    activity:{
        type:"select",
        label:"Activity Type",
        items:CAPITAL_ACTIVITY_TYPE_CHOICES,
        itemText:"title",itemValue:"const"
    },
    amount:{
        type:"number",
        label:"Payment Amount",
        col:COL_FULL_HALF_THIRD
    },
    src_account_id:{
        type:"InputAccount",
        label:'Source Account',hint:"Source Account",
        includeDefault:false,
        objectMode:false,
        col:COL_FULL_HALF_THIRD,
        allowCreate:true
    },
    dst_account_id:{
        type:"InputAccount",
        label:'Destination Account',hint:"Destination Account ",
        includeDefault:false,
        objectMode:false,
        col:COL_FULL_HALF_THIRD,
        allowCreate:true
    },
    payable_unit_id: {
        label:"Currency",
        type:"InputInstrument",
        filterType:InstrumentType.CURRENCY,
        hint:"Currency",
        [DETAILS_ANNOTATION]:true,
        objectMode:false
    },
    settled_event_id: {
        label:"Settled Event",
        ...eventInput,
        col:COL_FULL_HALF_THIRD,
    }
}
