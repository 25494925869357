import { compactInteger } from "humanize-plus"
import uiSettings from "~/utils/ui_settings"


export interface IFormatConfig{
    showSign:boolean    
    percentage:boolean
    compact:boolean
    decimalPlaces:number
    prefix:string
    suffix:string
}


export function formatNumber(value:number,fmt?:Partial<IFormatConfig>):string|undefined {
    let num!:string 
    if(!value) return;
    if(fmt?.percentage) num = getPercentFormatter().format(value)
    else if(fmt?.compact) num = compactInteger(value).toUpperCase()
    else num = value.toLocaleString(uiSettings.activeLocale,{
        minimumFractionDigits:fmt?.decimalPlaces ?? 2,
        maximumFractionDigits:fmt?.decimalPlaces ?? 2})
    return (fmt?.prefix ?? "") + num + (fmt?.suffix ?? "")
}


var PercentFormatter:Intl.NumberFormat|null =null

export function getPercentFormatter(){
    if(PercentFormatter == null){
        PercentFormatter =  new Intl.NumberFormat( uiSettings.activeLocale,{
            style:"percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        })
    }
    return PercentFormatter
}
