
import {
    Component,Vue,Prop,Watch,ModelSync, Getter
} from "nuxt-property-decorator"
import {transactionSearchExp} from "~/core/transactions"
import {VNode,CreateElement} from "vue"
import {VChip,VIcon} from "vuetify/lib"
import {ApiTransaction,ApiProfile,InstrumentType } from "~/schemas/gen"
import gql from "graphql-tag"
import {ZERO_UUID} from "~/utils/uuid"
import {
    describeTransaction,describeTransactionProspect,
    transactionLine2
}  from "~/components/transaction/render"
import {Transactions_Bool_Exp} from "~/models/graphql"


export type ApiTransactionNode = Partial<ApiTransaction>;


export const fragApiTransactionFields = gql`
fragment ApiTransactionFields on transactions {
    uuid transaction_id
    created_time
    line_item {
        account {  uuid name is_system_account }
        action
        unit_leg {
            instrument {
                display_name uuid identifier_type
                instrument_type
            }
            nominal_amount
        }
    }
}
`



const searchTransactions = gql`
query searchTransactions($transactionQ:transactions_bool_exp!){
transactions(where:$transactionQ){
    ...ApiTransactionFields
}
}
${fragApiTransactionFields}
`;

const initialTransaction = gql`
query initialTransaction($uuid:uuid!){
transactions(
    where: { uuid:{_eq: $uuid} }
    ) {
    ...ApiTransactionFields
    }
}
${fragApiTransactionFields}
`

@Component({
           components:{VChip,VIcon}
})
class TransactionItem extends Vue {
    /**
     * EntityItem -
     */
    @Prop() item!:ApiTransaction
    @Prop({default:false,type:Boolean}) extended!:boolean
    @Getter("user/systemUserId") systemUserId!:string|null



    render(h:CreateElement){
        let {item,extended,systemUserId} = this;
        let children:(VNode|string)[] = [ describeTransaction(item as any,true) ," " ]
        if(extended){
            if(item.transaction_id) children[0] = item.transaction_id
        }
        let chipChildren:(VNode|string)[] = []
        let id = item.uuid;

        if(chipChildren.length > 0){
            children.push(h("v-chip", { props:{small:true,label:true,
                outlined:true,color:"primary"}},
                            chipChildren ))
        }

        children.push(" ")
        if(extended){
            children.push(transactionLine2(item as any,h))
        }
        return h("div",{ class:{"transaction-input-select":true}},children)

    }


}

/**
 * Instrument Input component with combobox and autocomplete functionality
 */
@Component({
           inheritAttrs:true,
           components:{TransactionItem},
           apollo:{
               searchTransactions:{
                   query: searchTransactions,
                       variables():any {
                           let rawQ = transactionSearchExp(this.search)
                           let {filterQuery} = this
                           if(filterQuery){
                               rawQ = { _and:[rawQ,filterQuery]}
                           }
                           return {transactionQ:rawQ}
                       },
                       update: data => data.transactions,
                       skip() {
                            let {search} = this
                           return !search || this.search?.trim?.()?.length ==0
                       }
               },
                   initialTransaction:{
                       query:initialTransaction,
                           variables():any {
                               let uuid!:string
                               if(typeof(this.initValue) == "string") uuid = this.initValue
                               else uuid = this.initValue.uuid
                               return {uuid}
                           },
                           update: data => data.transactions[0],
                           skip(){
                               return !this.initValue
                           }
                   },
                   recentTransactions:{
                       query:gql`
                       query recent($q:transactions_bool_exp!) {
                       transactions(where:$q,limit:5,order_by:{transaction_time:desc}){
                       ...ApiTransactionFields
                       }
                       }
                       ${fragApiTransactionFields}
                       `, update: data => data.transactions,
                       variables():any {
                            let {filterQuery} = this;
                           if(filterQuery) return {q:filterQuery}
                           return {q:{}}
                       }
                   }
           }
})
export default class TransactionInput extends Vue {
    @ModelSync("value","input") input!:string|ApiTransaction;
    //@Prop({default: "name"}) outType!:InstrumentRefType
    @Prop({default:false}) filterType!:false|InstrumentType
    @Prop({}) vjsfContext!:any; //Optional Vjsf slot context
    @Prop() label!:string|null;
    @Prop() errorMessages!:any[]
    @Prop({default:false,type:Boolean}) objectMode!:boolean;
    @Prop({default:false,type:Boolean}) viewLink!:boolean


    search:string =  ""
    loading:boolean = false
    searchTransactions:ApiTransaction[] = []
    recentTransactions:ApiTransaction[] = []
    initialTransaction:ApiTransaction|null = null
    initValue:ApiTransaction|string|null = null
    itemError:Error|any|null = null
    fixed:boolean = false


    get passedListeners():object{
        let {input,...captured} = this.$listeners
        return   captured;
    }
    async mounted(){
        let {input} = this
        this.initValue = input;
    }

    get displayLabel():string{
        return this.label || this.vjsfContext?.label
    }
    get items():ApiTransaction[]{
        let entries:ApiTransaction[] = []
        let {input:value,search} = this;
        if(this.searchTransactions.length > 0) entries.push(...this.searchTransactions)
        if(this.initialTransaction) entries.push(this.initialTransaction)
        if(this.recentTransactions.length >  0 ) entries.push(...this.recentTransactions)
        return entries
    }
    get filterQuery():Transactions_Bool_Exp|null {
        if(!this.filterType) return null;
        return {
            legs:{
                instrument: {
                    instrument_type:{ _eq:this.filterType }
                }
            }
        }
    }

    get selectInput():string|null {
        if(!this.input) return null;
        if(typeof(this.input) == "string") return this.input;
        return  this.input.uuid!
    }
    get bindings():object{

        let binding:Record<string,any>= {
            ...this.$attrs,
            placeholder:"Search for Transaction",
            label:this.displayLabel,
            valueComparator:this.compareTransaction,
            itemText:this.getItemText,
            itemValue:this.getItemValue,
            returnObject:true
        }
        if(this.viewLink || this.hasValue ) binding.appendOuterIcon= "fas eye"
        return binding
    }
    get hasValue():boolean {
        if(!this.input) return false;
        let value = this.getItemValue(this.input)
        if(!value || value ==  ZERO_UUID || value?.length == 0) return false;
        return true;
    }
    onOpenView(){
        let value = this.getItemValue(this.input)
        if(!this.hasValue) return;
        let lnk = this.$router.resolve({name:"transaction-id-edit",
                                       params:{ id: value }
        });
        window.open( lnk.href);
    }

    onItemSelected(x:ApiTransaction|null){
        if( x == null) {
            this.$emit("input",null)
            return
        }
        let ret!:any

        if(this.objectMode){
            ret =x
        }else ret = x.uuid!

        this.$emit("input",ret)
    }
    onKeyDown($event:KeyboardEvent){
        if($event.keyCode == 13){
            //Pressing retunr will choose the first suggestion
            $event.stopPropagation();
            $event.preventDefault();
            this.selectFirst();
        }
    }

    selectFirst(){
        if(this.items.length > 0){
            this.onItemSelected(this.items[0])
        }
    }

    compareTransaction(x:ApiTransaction|string,y:ApiTransaction|string):boolean {
        if(x === y ||  x == y  ) return true
        if(x == null || y == null) return false;
        if(typeof(x) == "string"){
            if(typeof(y) == "object") return x == y.uuid
            return false
        }
        if(typeof(y) == "string") return y === x.uuid
        return false;
    }
    getItemText(item:ApiTransaction){
        if(typeof item === "string") return item
        return describeTransaction(item as any,true)
    }

    getItemValue(x:ApiTransaction|string){
        if(typeof x === "string") return x;
        return x.uuid
    }


}

