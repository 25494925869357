/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiAccount = {
    description: `Account submitted by user for input or update`,
    properties: {
        name: {
            type: 'string',
            isRequired: true,
            maxLength: 128,
        },
        group: {
            type: 'string',
            maxLength: 20,
        },
        bs_label: {
            type: 'string',
            maxLength: 4,
        },
        settlement_account_id: {
            type: 'string',
            format: 'uuid',
        },
        acct_number: {
            type: 'string',
            maxLength: 20,
        },
        acl: {
            type: 'ApiPermissions',
        },
        notes: {
            type: 'string',
        },
        is_archived: {
            type: 'boolean',
        },
        is_self_settled: {
            type: 'boolean',
        },
        uuid: {
            type: 'string',
            format: 'uuid',
        },
        entity_id: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'uuid',
            }, {
                type: 'Enum',
            }],
        },
        owner_id: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'uuid',
            }, {
                type: 'Enum',
            }],
        },
        date_established: {
            type: 'string',
            format: 'date',
        },
        acct_type: {
            type: 'all-of',
            contains: [{
                type: 'AcctType',
            }],
        },
        import_facet: {
            type: 'ImportFacet',
        },
        client_identifier: {
            type: 'string',
        },
        prospect_id: {
            type: 'string',
            format: 'uuid',
        },
    },
} as const;
