import Vue,{DirectiveBinding,VNode,nextTick} from "vue"


/**
 * Directive to scroll things into view
 */
Vue.directive("scroll-into-view",{
    inserted(el:HTMLElement,binding:DirectiveBinding<ScrollIntoViewOptions & {parent?:boolean}>,node:VNode){
        nextTick().then(() => {
            setTimeout(() => {
              if(binding.value.parent) el = el.parentNode as HTMLElement
              el.scrollIntoView(binding.value)
            },200)
        })
    }
})
