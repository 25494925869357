/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DataJoinType {
    INNER = 'inner',
    OUTER = 'outer',
    RIGHT = 'right',
    FULL = 'full',
    SEMI = 'semi',
    ANTI = 'anti',
    PREPEND = 'prepend',
    APPEND = 'append',
}
