import { render, staticRenderFns } from "./SchemaForm.vue?vue&type=template&id=5bb11db9"
import script from "./SchemaForm.vue?vue&type=script&lang=ts"
export * from "./SchemaForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LocalFormBase: require('/app/components/LocalFormBase.vue').default,GridTreeNode: require('/app/components/grid/TreeNode.ts').default})
