

import { addDays, parseISO,differenceInDays } from "date-fns";
import { absoluteDateOnly, localDate } from "~/utils/dates";
import { PricingService,ObservableField,
    InstrumentTimeSeriesRequest,
    ApiObservable,InputApiObservable,
    TimeSeriesResponse,
    ResponseSeriesError,
ResponseSeries} from "~/schemas/gen";

import Batcher from "~/core/utils/batcher"
import {SymMappingObservable} from "~/core/observable";
import {ulid as  new_ulid } from "ulid"
import { PricingResponse } from ".";

const {SYM_ALT_NAME} = ObservableField

//Poisitions get a wide window
export const POSITION_CONTEXT_WINDOW = 7
//Symbol mappins will have a tighter context
export const SYMBOL_MAPPING_CONTEXT_WINDOW = 3

export interface PricingContext {
    date?:Date
    context_window:number
}

export const BLANK_PERIOD:InstrumentTimeSeriesRequest['query'] = {start_time:"P0D",end_time:"P0D"}


/// Request for Symbol mapping
//THis is don by the ALT name not the identifier
//and uses a date from context
export function requestForMapping(mapping:SymMappingObservable,
    context:PricingContext):InstrumentTimeSeriesRequest {
        if(!context.date){
            throw new Error("Pricing for Symbol Mapping Requires {context.date}")
        }
        const query ={
            start_time:absoluteDateOnly(addDays(context.date!,context.context_window*-1)),
                end_time:absoluteDateOnly(addDays(context.date!,context.context_window))
        }
        return {
            instruments:[{
                key:"*:*:" + mapping?.fields?.[SYM_ALT_NAME],
                columns:['*'],
                query
            } ],query:BLANK_PERIOD
        }
}

/**
 * Get a request for a position (Using the time it contains)
 */
export function requestForPosition(position:InputApiObservable|ApiObservable, context:PricingContext):InstrumentTimeSeriesRequest|null {
    let {date_of,identifier} = position
    if(!date_of) return null
    let dt!:Date
    if(typeof date_of === "string"){
        dt = localDate(date_of)
    }else dt = date_of  as any
    const query ={
                start_time:absoluteDateOnly(addDays(dt,context.context_window*-1)),
                end_time:absoluteDateOnly(addDays(dt,context.context_window))
            }
    return {
        instruments:[{
            key: "*:*:" + identifier ,
            columns:["*"],
            query
        }],query:BLANK_PERIOD
    }
}


const REQUEST_KEYS:Record<string,InstrumentTimeSeriesRequest> = {}



async  function pricingKeyRequest(keys:string[]):Promise<Record<string,PricingResponse>>{
    let req:InstrumentTimeSeriesRequest = {
        instruments:keys.map(x => {
            try{
                return REQUEST_KEYS[x]!.instruments[0]
            }finally {
                delete REQUEST_KEYS[x]
            }
        }),
        query:BLANK_PERIOD
    }
    let resp:TimeSeriesResponse = await PricingService.getPrices(req)

    return Object.fromEntries(keys.map((k,index) => {
        let entry = resp.series![index]
        return [k,entry ]
    }))

}

export function keyForRequest(req:InstrumentTimeSeriesRequest):string {
    let k = new_ulid()
    REQUEST_KEYS[k] =  req
    return k
}


export const pricingBatcher = new Batcher<PricingResponse,string>(pricingKeyRequest)


