import { render, staticRenderFns } from "./UploadDialog.vue?vue&type=template&id=0eab2ae5"
import script from "./UploadDialog.vue?vue&type=script&lang=ts"
export * from "./UploadDialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DocumentView: require('/app/components/document/View.vue').default,DocumentLinkPanel: require('/app/components/document/LinkPanel.vue').default,Flow: require('/app/components/flow/index.ts').default})
