/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiDocumentLinkUpdate = {
    description: `Api Docuemtn taht is just links`,
    properties: {
        link_updates: {
            type: 'ApiDocumentLinkUpdates',
        },
    },
} as const;
