/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OtherDetails = {
    detail_type?: OtherDetails.detail_type;
};

export namespace OtherDetails {

    export enum detail_type {
        OTHR = 'OTHR',
    }


}

