import { render, staticRenderFns } from "./PriceAssistant.vue?vue&type=template&id=41c94cbe"
import script from "./PriceAssistant.vue?vue&type=script&setup=true&lang=ts"
export * from "./PriceAssistant.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PriceAssistant.vue?vue&type=style&index=0&id=41c94cbe&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FmtNumber: require('/app/components/fmt/Number.vue').default,UtilsPass: require('/app/components/utils/pass.ts').default,InputInstrument: require('/app/components/input/Instrument.vue').default})
