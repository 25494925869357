/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputField = {
    properties: {
        identifier: {
            type: 'string',
            isRequired: true,
            pattern: '[A-Za-z][A-Za-z0-9_]*',
        },
        field_type: {
            type: 'all-of',
            contains: [{
                type: 'FieldTypes',
            }],
        },
        is_nullable: {
            type: 'boolean',
        },
        elements: {
            type: 'array',
            contains: {
                type: 'InputElement',
            },
        },
        width: {
            type: 'number',
        },
        precision: {
            type: 'number',
        },
        scale: {
            type: 'number',
        },
        default: {
            type: 'string',
        },
        name: {
            type: 'string',
            isRequired: true,
        },
        flat_tags: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        unique: {
            type: 'boolean',
        },
        attributes: {
            properties: {
            },
        },
    },
} as const;
