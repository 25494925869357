
/**
 * Request Batching class
 */
import { debounce } from "lodash"
import {completable,CompletablePromise } from "./promise"
import {DocumentNode} from "graphql";


export default class Batcher<T,K extends string|number > {
    provider:(items:K[]) => Promise<Record<K,T>>;
    queue:K[];
    pending:CompletablePromise<Record<K,T>>|null = null
    execute:() => void
    limit:number = 20

    
    constructor(provider:(items:K[]) => Promise<Record<K,T>>){
        this.queue = []
        this.provider = provider
        this.execute = debounce(() => {
            //Execute clears the queue and the pending
            this.provider(this.queue).then(this.pending!.resolve,this.pending!.reject)
            this.queue = []
            this.pending = null

        })
    }
    
    async request(item:K):Promise<T|null> {
        this.queue.push(item)
        if(!this.pending){
            this.pending = completable()
        }
        //Trigger an execute
        this.execute()
        //When pending is complete we will return the item with a lookup
        return this.pending!.then(x => {
            return x[item]
        })
    }




    
    
}

/**
 * Batching function that uses Apollo for resolution. 
 * it take a query that accepts a single variable values
 */
export function apolloBatcher<T>(query:DocumentNode,key:keyof T) {
    return     (clientId:string) => {
        return async (values:string[]) => {
            const { getClient } =await import("~/plugins/apollo.nuxtlike")

            let cli = getClient(clientId)
            let res =  await cli.query({ query,variables:{values} })
            if(res.error) throw res.error
            return Object.fromEntries<T>(res.data.accounts.map((x:T) => {
                return [x[key],x]
            }))
        }
    }
}
