/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataTabularTab = {
    properties: {
        title: {
            type: 'string',
        },
        header: {
            type: 'any-of',
            contains: [{
                type: 'string',
            }, {
                type: 'EtlVisualBlock',
            }],
        },
        footer: {
            type: 'any-of',
            contains: [{
                type: 'string',
            }, {
                type: 'EtlVisualBlock',
            }],
        },
        preview: {
            type: 'boolean',
        },
        identifier: {
            type: 'string',
        },
    },
} as const;
