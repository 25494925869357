import Vue,{VNode,CreateElement,VNodeChildren,RenderContext} from "vue"

const Pass = Vue.component('utils-pass',{
    functional:true,
    render(h:CreateElement,context:RenderContext<any>):VNode {
        let {scopedSlots,props} = context;
        return scopedSlots?.default?.(props) as any
    }
})

export default Pass
