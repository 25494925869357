/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiEvent = {
    description: `This is an amalgamation of all the event types .
    We user separate fields instead of a large Union because it
    will make the intended type explicit`,
    properties: {
        event_type: {
            type: 'EventType',
            isRequired: true,
        },
        description: {
            type: 'string',
            maxLength: 100,
        },
        identifier: {
            type: 'string',
        },
        instant: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date',
            }, {
                type: 'string',
                format: 'date-time',
            }],
            isRequired: true,
        },
        parent_event_id: {
            type: 'string',
            format: 'uuid',
        },
        new_transaction: {
            type: 'InputApiNewTransactionEvent',
        },
        capital_call: {
            type: 'InputApiCapitalCallEvent',
        },
        capital_distro: {
            type: 'InputApiCapitalDistroEvent',
        },
        capital_activity: {
            type: 'InputApiCapitalActivityEvent',
        },
        event_data: {
            type: 'EventData',
        },
        advisor_note: {
            type: 'string',
        },
        acl: {
            type: 'ApiPermissions',
        },
        settlement_notes: {
            type: 'array',
            contains: {
                type: 'InputApiSettlementAccountEntry',
            },
        },
        notes: {
            type: 'string',
        },
        prospect_id: {
            type: 'string',
            format: 'uuid',
        },
        import_facet: {
            type: 'ImportFacet',
        },
    },
} as const;
