/**
 * Component represents a Flow through a bunch of screens
 * A flow is a sequence of components|functions that select components
 *
 */

import {defineAsyncComponent, defineComponent} from "vue"
import pick from "lodash/pick"
import debounce from "lodash/debounce"


export type ErrorFunc = (f:FlowActivity,flow:Flow) => boolean;

type BoolFunc = () => boolean
type FlowStepFunc =(a:FlowActivity,flow:Flow,value:any) => boolean
type FlowStepAnyFunc = (...args:any) =>  boolean
type  ErrorFilterFunc = (x:string)=>boolean;
export interface IFlowMeta{
    input_pane?:boolean;
    note_pane?:boolean;
    upload_panel?:boolean;
    isEditable?:BoolFunc|FlowStepFunc|FlowStepAnyFunc;
    isComplete?:BoolFunc|FlowStepFunc|FlowStepAnyFunc;
    error_fields?:(string|ErrorFilterFunc)[];
    [key:string]:any;
}

export type PropProvider = (on:IFlowActivityEvents<any>,payload:any,sender?:any) => Record<string,any>
export type EventProvider = (on:IFlowActivityEvents<any>,payload:any,sender?:any) => Record<string,any>
export interface FlowActivity {
    id:number|string //Id for activity
    target:string //Target of activity (this is a slot name or a component name)
    component?:Function|ReturnType<typeof defineComponent>|ReturnType<typeof defineAsyncComponent>
    actionComponent?:Function|ReturnType<typeof defineComponent>|ReturnType<typeof defineAsyncComponent>
    labelComponent?:Function|ReturnType<typeof defineComponent>|ReturnType<typeof defineAsyncComponent>
    props?:Record<string,any>
    on?:Record<string,Function>
    dynamicProps?:PropProvider; //Props for the component
    dynamicOn?:EventProvider; //Event handlers for component
    actionProps?:Record<string,any>|PropProvider
    actionOn?:Record<string,Function>|EventProvider
    labelProps?:Record<string,any>|PropProvider
    title?:string // Title for the Activity
    sub_title?:string //Sub Title for step
    payload_target?:string //Prop to sent payload
    meta?:IFlowMeta // Data related to FlowActivity for the owner
}
export type FlowId = FlowActivity["id"]

interface FlowActionResult{
    action:string
    payload?:any
}
interface FlowRedirectResult {
    to:string
    payload?:any
}

export interface ISpanQuery {
    from?:FlowId
    after?:FlowId
    until?:FlowId
}

export type FlowBranchResult =  FlowRedirectResult|FlowActionResult;


//Flow Branch takes output and makes a choice
export interface FlowBranch {
    id:FlowId
    onEnter(src:FlowActivity,flow:Flow,payload:any):FlowBranchResult|Promise<FlowBranchResult>
    meta?:Record<string,any> // Data related to FlowActivity for the owner
}
export type FlowItem = FlowBranch |  FlowActivity

export function isActivity(x:FlowItem):x is FlowActivity {
    return "id" in x &&  "target" in x
}

export function isBranch(x:FlowItem):x is FlowBranch {
    return typeof (x as any).onEnter === "function"
}

export enum FlowAction{
    ACT_DONE = "DONE",
        ACT_OK = "OK",
        ACT_CXL = "CANCEL"
}
export const FlowClose:FlowBranch = {
    id:FlowAction.ACT_DONE,
    async onEnter(src:FlowActivity,flow:Flow,payload:any):Promise<FlowBranchResult> {
        return {action:FlowAction.ACT_OK}
    }
}

/**
 * Specification of a FLow
 * Flow os technically a Graph but will be
 * listed as an ordered set of states
 */
export class FlowSpec {
    //Actual reference to flow item
    states:Record<FlowId,FlowItem> = {}
    // Maps a particulr state id -> and index
    stateIndex:Record<FlowId,number> = {}
    entries:(FlowId)[]  = []
    nextId:number =1;
    nextActivity:number = 1
    constructor(items:FlowItem[]){
        this.withEntries(...items)
    }

    /**
     * Make a copy of this Flow specification
     */
    clone():FlowSpec{
        let spec =  Object.assign(
            new FlowSpec(Object.values(this.states)),
            pick(this,["nextId","nextActivity"]))
        return spec;
    }

    /// Filter  the items in this flow spec
    flatMap(func:(a:FlowItem,index:number)=>FlowItem[]):FlowSpec {
        return new FlowSpec(Object.values(this.states).flatMap(func))
    }

    withEntries(...items:FlowItem[]):FlowSpec{
        for(let x of items){
            if(!x.id) x = {...x,id:this.newId() }
            else x = {...x}
            this.entries.push(x.id);
            this.states[x.id] =  x
            if(isActivity(x)){
                this.stateIndex[x.id] = this.nextActivity ++
            }
        }
        return this;
    }
    newId():number{
        while(true){
            let nextId = ++this.nextId
            if(nextId in this.states) continue
            return nextId
        }
    }
    //Add a branch
    branch(func:FlowBranch["onEnter"]):FlowSpec {
        return this.withEntries({
            id:this.newId(),
            onEnter:func
        })
    }
    //Add a more stable id to the last flow item.
    named(name:string):FlowSpec  {
        let lastKey = this.entries.pop()
        this.states[lastKey!].id=name
        this.entries.push(name)
        this.states[name]=  this.states[lastKey!]
        if(lastKey! in this.stateIndex){
            this.stateIndex[name] = this.stateIndex[lastKey!]
        }
        delete this.states[lastKey!]
        return this
    }

    *activities(start?:number,end?:number):Generator<FlowActivity>{
        for (let id of this.entries){
            let e = this.states[id]
            if(isActivity(e)) yield e;
        }
    }
    *span(qry:ISpanQuery):Generator<FlowItem> {

        let matching:boolean=false
        if(qry.from) matching = false;
        else if(qry.after) matching =false;
        else matching=true;
        for(let id of this.entries){
            if(!matching) {
                if(qry.from) matching = qry.from=== id;
                else if(qry.after) matching = qry.after === id;

                if(!qry.from || !matching) continue
            }
            if(qry.until && id ==qry.until) break;
            yield this.states[id]
        }
    }


    //Given an offset in the flow return the Activity
    at(x:number):FlowActivity|undefined {
        for(let actId in this.stateIndex){
            let idx = this.stateIndex[actId];
            if(idx == x){
                return this.states[actId] as FlowActivity
            }
        }
    }
    // Return the flowId at an index
    idAt(x:number):FlowId|undefined {
        for(let actId  in this.stateIndex){
            if(x == this.stateIndex[actId]) return actId
        }
    }
    activityAfter(id:FlowId):FlowId|undefined {
        let off = this.entries.indexOf(id);
        if(typeof off === "undefined") return undefined
        for(let i = off+1;i<this.entries.length;++i){
            let id = this.entries[i]
            if(id in this.stateIndex) return id;
        }
    }
    //Return the index for a particular flowId
    indexOf(id:FlowId):number {
        return this.stateIndex[id]
    }
}





export interface ToActivityOptions {

}


export interface IFlowActivityEvents<T>{
    activity:FlowActivity
    input(payload:T):void
    change(payload:T):void
}

export default interface Flow {
    toActivity(id:FlowId|boolean,opts?:Partial<ToActivityOptions>):Promise<true|undefined>;
    hasStepState(id:FlowId):boolean;
    cancel(...args:any[]):void;
}


