import { ResponseSeries,ResponseSeriesError } from "~/schemas/gen"
import { addDays, parseISO,differenceInDays } from "date-fns";
import {range, sortBy} from "lodash";
export type PricingResponse = ResponseSeries|ResponseSeriesError




export function nearestDate(dt:Date|string,series:PricingResponse,index:number=0){
    if(!series) return {
        error: { 
            message:"No Pricing found"
        }
    }
    if("message" in series) return {error:series}
    if(typeof dt === "string") dt = parseISO(dt)
    try{
    let dates = series.data['date'].map(x => parseISO(x))
    let closes =  series.data['close'] ?? series.data['price']
    let indexes = range(0,dates.length!)
    let items = sortBy(indexes,[
        x =>  { 
            let thisDt =dates[x]
            let diff = differenceInDays(dt as Date,thisDt)
            if(diff < 0){
                diff = -diff + 0.01
            }
            return diff
        }
    ])

    return {
        items,
        date:  dates[items[0]],
        price: closes[items[0]]
    }
    }catch(e){
        console.error("Error getting nearest price",e)
        return {error:{message:"No Pricing Found"}}
    }
}
