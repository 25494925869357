
import {components} from "./api"
import mapValues from "lodash/mapValues"
import pick from "lodash/pick"
import {ExpandedSchema} from "./enrichment"
import {Context} from "@nuxt/types"
import {
    $CapitalCommitmentDetails, InstrumentRef,InstrumentType,InstrumentRefType,
    InputApiEvent,InputApiNewTransactionEvent,FundNotionalTarget,
    AmortizationType
} from "~/schemas/gen"
import {Schema,SchemaSet} from "~/utils/schema_funcs"
import {FlatSchemaKey,FLOW_ANNOTATION,DETAILS_ANNOTATION,IMPORT_ANNOTATION} from "./common"
export {FlatSchemaKey,FLOW_ANNOTATION,DETAILS_ANNOTATION} from "./common"


export const defaultValuesKey =  Symbol("transaction_schema.default_values")
export const SafeCapitalizationFieldsKey = Symbol("transaction_schema.safe.capitalization_fields")


export type ChoiceList = Array<{
    "const":string,
    title:string
}>

type OptChoiceList = ChoiceList | undefined
export type AccountRef = components["schemas"]["AccountRef"]
const CURRENCY_LIST = ["USD","EUR","JPY","GBP"].map(x => ({"const":x,title:x}))
const COUNTERPARTY_LIST = [
    {"const":"ISR",title:"Company"},
    {"const":"MKT",title:"Market"}
]

const FUND_NOTIONAL_TARGET_LIST = [
    {const:FundNotionalTarget.INVESTED_CAPITAL,title:"Invested Capital"},
    {const:FundNotionalTarget.COMMITTED_CAPITAL,title:"Committed Capital"},
]

export const InstrumentTypes:Record<string,InstrumentType> = {
    CCY:InstrumentType.CURRENCY,
    REAL:InstrumentType.REAL_ESTATE
}

export const COL_FULL_HALF_THIRD = {xs:12,sm:6,md:3}

export const BUY_OR_SELL=[
    {"const":"B","title":"Buy"},
    {"const":"S","title":"Sell" },
    {"const":"P","title":"Pay" },
    {"const":"R","title":"Receive" }
]
export const PERIODICITY_LIST = [
    {"const":"D",title:"Daily"},
    {"const":"W",title:"Weekly"},
    {"const":"B",title:"Bi-Weekly"},
    {"const":"M",title:"Monthly"},
    {"const":"Q",title:"Quarterly"},
    {"const":"S",title:"Semi-Annually"},
    {"const":"A",title:"Annually"},
]
const NOTIONAL_EXCHANGE_LIST = [
    {"const":"I",title:"Initial"},
    {"const":"F",title:"Final"},
    {"const":"B",title:"Both"}
    ]

const FIXED_OR_PERCENTAGE_LIST = [
    {"const":"F",title:"Fixed"},
    {"const":"%",title:"Percentage"}
]

const COUPON_TYPE_LIST = [
    {"const":"FIX",title:"Fixed"},
    {"const":"FLT",title:"Floating"},
    {"const":"IND",title:"Index Notional"},
]

const INTEREST_PAYMENT_CONVENTION_LIST = [
    {"const":"FIX",title:"Fixed"},
    {"const":"ARM",title:"Floating"},
]

const RECUR_TYPE_LIST = [
    {"const":"S",title:"Fixed Amount"},
    {"const":"C",title:"Coupon Amount"}
]

const AMORTIZATION_TYPE_LIST = [
    {"const":AmortizationType.TRADITIONAL,title:"Mortgage"},
    {"const":AmortizationType.STRAIGHT,title:"Straight Line"}
]

const defaultFieldProps = {
    //dense:true
}




const optFloat = {
    type:"number"
}
const periodicityInput = {
    type:"select",
    items:PERIODICITY_LIST,
    itemText:"title",itemValue:"const"
}
const  interestPaymentConventionInput = {
    type:"select",
    items:INTEREST_PAYMENT_CONVENTION_LIST,
    itemText:"title",itemValue:"const"
}
const notionalTargetInput = {
    type:"select",
    items:FUND_NOTIONAL_TARGET_LIST,
    itemText:"title",itemValue:"const"
}
const rateInput = {
    type:"number",label:"Percentage",
    prefix:'%'
}

export const instrumentInput = {
    type:"InputInstrument",
    col:{ sm:6,md:3},
    /*toCtrl({data,value,key}:{data:any,value:any,key:string}){
        console.log("Construct ->",value,key)
        return value
    }*/
}

export const transactionInput = {
    type:"InputTransaction"
}

export const dateInput = {
    type:"InputDate",
    returnString:true
}

export const FlatTransactionSchema = {
    asset_type: { type:'select',label:"Asset/Item Type",
        hint:"What kind of thing did you Buy/Sell",
        [FLOW_ANNOTATION]:{
            overrides:{ label:"What is it" }
        },
        col:{ sm:12 }
    },
    what:{
        ...instrumentInput,
        label:'Instrument / Security',
        hint:"What did you Buy or Sell",
        col:{md:6,xs:12},
        [FLOW_ANNOTATION]:{
            overrides:{
                label:"What is it?"
            }
        },
        allowCreate:true
    },
    direction:{
        type:"select",label:"Direction",
        hint:"Buy/Sell/Pay/Receive?",
        items:BUY_OR_SELL,
        [FLOW_ANNOTATION]:{
            overrides:{
                label:'What is happening?'
            }
        },
        itemText:"title",itemValue:"const",
        clearable:false,
        col:{md:3,xs:6}
    },
    when:{
        type:'text',
        ext:'date',
        label:'Transaction Date',hint:'When did you complete the transaction?' ,
        col:{sm:6, md:3 }
    },
    target_account:{
        type:"InputAccount",
        label:'Target Account',hint:"Related Account",
        includeDefault:true,
        objectMode:true,
        col:{xs:12,sm:4},
        allowCreate:true
    },
    counterparty_account:{
        type:"InputAccount",
        label:'Counter party Account',hint:"Counterparty Account",
        includeDefault:false,
        objectMode:true,
        col:{xs:12,sm:4},
        allowCreate:true
    },
    name:{
        type:'text',
        label:"Transaction Name",
        hint:"Give this transaction a name for your records"
    },
            counterparty:{
                type:"select",
                label:"Counter-Party",
                hint:"Where/Who  is this From?",
                items:COUNTERPARTY_LIST,itemText:"title",itemValue:"const",
                col:{xs:6,md:3}
            },
            price_currency:{
                ...instrumentInput,
                label:"Consideration Unit/Currency",
                /*outlined:true,clearable:false,*/
                filterType:InstrumentTypes.CCY,
            },
            price:{
                type:"number",
                label:"Price (Per/Unit)",
                hint:"What did you pay/receive?",
                col:{xs:12,md:3},
                prefix:"$"
            },
            units:{
                type:"number",
                label:"Nominal Amount",
                hint:"Units of Asset/Item",
                col:{xs:12,sm:6,md:3}
            },
            fair_market_value: {
                type:"number",
                label:"Fair Market Price(FMV)",
                hint:"What would this cost?",
                col:{xs:12,sm:6,md:3},
                prefix:"$"
            },
            fair_market_value_unit: {
                ...instrumentInput,
                label:"FMV Unit",
                filterType:InstrumentTypes.CCY,
                hint:"What currency is ?",
                col:{xs:6,md:3}
            }
}



export const FlatInstrumentSchema=  {
    issue_date:{
        type:'text',
        ext:'date',
        label:'Issue Date',hint:'When did this instrument get issued' ,
        col: COL_FULL_HALF_THIRD,
    },
    issuer: {
        type:'InputEntity',hint:"Issuer of Security",
        label:'Issuing Entity',
        allowCreate:true,
        objectMode:true,
        refMode:true,
        col:COL_FULL_HALF_THIRD
    },
    id: {
        ...instrumentInput,
        label:'Instrument / Security',
        hint:"What did you Buy or Sell",
        col:{md:6,xs:12},
        [FLOW_ANNOTATION]:{
            overrides:{
                label:"What is it?"
            }
        },
        allowCreate:true
    },
    type: {
        type:'select',label:'Instrument Ref Type',
        col:{xs:3} ,
        hidden:true
    }
    /* Details is a subschema that is imported*/
}


const TRANSACTION_SCHEMA = {
    ...pick(FlatTransactionSchema,["asset_type","what","direction","when",
        "target_account", "counterparty_account",
        "name"])
    , pricing:{
        type:'wrap',
        title:"Price / Consideration /  Market Value",
        elevation:1,
        class:"title pa-2",
        schema:{
            ...pick(FlatTransactionSchema,[
                "counterparty","price",
                "price_currency","units",
                "fair_market_value","fair_market_value_unit"])
        }
    },
    [defaultValuesKey]:{
        counterparty:"MKT"
    }
}




function withPrefix(prefix:string,obj:Record<string,any>):Record<string,any> {
    return Object.fromEntries(Object.entries(obj).map(([k,v]) => [prefix+k,v]))
}

// Render a hidden detail_type field
function detailType(value:string){
    return {
        detail_type:{
            label:"Detail Type",
            type:"text",
            disabled:true,
            hidden:true,
            default:value
        }
    }
}
export const EquityDetailsSchema = {
    ...detailType("EQTY"),
    ...mapValues( withPrefix("dividend_",{
        amount:{
            type:"number",label:"Expected Dividend",
            prefix:"$",
            [DETAILS_ANNOTATION]:{
                itemClass:"important"
            }
        },
        unit_id:{
            label:"Dividend Currency",
            ...instrumentInput,
            objectMode:false,
            filterType:InstrumentTypes.CCY,
            hint:"Dividend Security",
        }
    }),(value) => {
        return {
            col:{ xs:6  },
            ...value
        }
    }),
    periodicity: {
        label:"Dividend Frequency",
        ...periodicityInput,
        col:{xs:6,md:3 },
        [DETAILS_ANNOTATION]:{
            itemClass:"important"
        },
    },
    ...mapValues(withPrefix("preference_",{
        price:{label:"Price", ...optFloat,[DETAILS_ANNOTATION]:{number:{}}},
        multiple:{label:"Multiple", ...optFloat,[DETAILS_ANNOTATION]:{number:{}}},
    }),x => ({...x,col:{md:3,xs:6} })),
    ...mapValues(withPrefix("conversion_",{
        ratio:{label:"Ratio", ...optFloat},
        cost_per_share:{prefix:"$",label:"Cost Per Share", ...optFloat},
    }),x => ({...x,col:{md:3,xs:6},[DETAILS_ANNOTATION]:{number:{}}})),
    participation_cap_multiple:{label:"Cap Multiple", ...optFloat,col:{md:3,xs:6},[DETAILS_ANNOTATION]:true }
}

export const EquityDetailsSchemaGrouped = {
    [FlatSchemaKey]:EquityDetailsSchema,
    type:"group",
    group:"tabs",
    schema: {
        dividend:{
            type:"wrap",
            title:"Dividend",
            schema:pick(EquityDetailsSchema,["detail_type","periodicity"]
                .concat(["amount","unit_id"].map(x => `dividend_${x}`)))
        },
        liquidity:{
            type:"wrap",
            title:"Liquidity Preference",
            schema:pick(EquityDetailsSchema,
                ["price","multiple"].map(x => `preference_${x}`))
        },
        conversion:{
            type:"wrap",
            title:"Conversion",
            schema:mapValues(withPrefix("conversion_",{
                ratio:{label:"Ratio", ...optFloat},
                cost_per_share:{prefix:"$",label:"Cost Per Share", ...optFloat},
            }),x => ({...x,col:{md:3,xs:6}}))
        },
        participation:{
            type:"wrap",
            title:"Participation",
            schema:pick(EquityDetailsSchema,["participation_cap_multiple"])
        }
    }
}

export const RealEstateDetailsSchema = {
    ...detailType("REAL"),
    real_estate_tax:{
        type:"number",label:"Real Estate Tax",
        'col':{ md:6 },
        suffix:"%",
        [DETAILS_ANNOTATION]:{
            number:{percent:true,suffix:"%"}
        }

    },
    real_estate_tax_frequency:{
        label:"Tax Frequency",
        ...periodicityInput,
        'col':{ md:6 },
        [DETAILS_ANNOTATION]:true
    },settlement_unit_id:{
        label:"Fee Settlement CCY",
        ...instrumentInput,
        filterType:InstrumentTypes.CCY,
        hint:"Currency",objectMode:false,
        [DETAILS_ANNOTATION]:true
    },
    ...mapValues({
        entry_brokerage_fee: {label:"Brokerage Fee",...optFloat},
        exit_brokerage_fee: {label:"Exit Brokerage Fee",...optFloat},
        other_fees: {label:"Other Closing Fee(s)",...optFloat},
    },(x) => {
        return {...x,
            col:{xs:12,md:6} , [DETAILS_ANNOTATION]:true
        }
    }),
    ...mapValues({
        bedrooms:{label:"# of Bedrooms",...optFloat},
        bathrooms:{label:"# of Bathrooms",...optFloat},
        interior_sq_ft:{label:"Interior Sq. Footage",...optFloat,suffix:"sq. ft"},
        exterior_sq_ft:{label:"Exterior Sq. Footage",...optFloat,suffix:"sq. ft" },
        postal_code: {label:"Postal Code",type:"text"}
    },(x) => {
        return {...x,
            col:{xs:12,md:6} , [DETAILS_ANNOTATION]:true
        }
    })
}

export const RealEstateDetailsSchemaGrouped  = {
    [FlatSchemaKey]:RealEstateDetailsSchema,
    type:"group",
    group:"tabs",
    schema:{
        taxes:{
            title:"Taxes",
            type:"wrap",
            schema:pick(RealEstateDetailsSchema,["real_estate_tax","real_estate_tax_frequency","postal_code"])
        },
        related_fees:{
            type:"wrap",
            title:"Related Fees",
            schema:pick(RealEstateDetailsSchema,[
                "entry_brokerage_fee",
                "exit_brokerage_fee",
                "settlement_unit_id",
                "other_fees",
                "association_fees"])
        },
        property_details:{
            type:"wrap",
            title:"Dimensions",
            schema:pick(RealEstateDetailsSchema,[
                "bedrooms","bathrooms","interior_sq_ft","exterior_sq_ft"
            ])
        }
    }
}

export const BondDetailsSchema = {
    ...detailType("BOND"),
    notional_exchange:{
        type:"select",label:"Notional Exchange",
        items:NOTIONAL_EXCHANGE_LIST,
        itemText:"title",itemValue:"const",
        col:{ md:3,xs:6 },
        [DETAILS_ANNOTATION]:true
    },
    payable_unit_id:{
        label:"Payable Currency",
        ...instrumentInput,
        filterType:InstrumentTypes.CCY,
        hint:"Currency",objectMode:false,
        [DETAILS_ANNOTATION]:true
    },
    periodicity: {
        label:"Frequency",
        ...periodicityInput,
        col:{ md:3,sm:6 },
        [DETAILS_ANNOTATION]:true
    },
    first_pay_date:{
        ...dateInput,
        label:"First Payment Date",
        col:{ md:3,sm:6 }
    }, last_pay_date:{
        ...dateInput,
        label:"Last Payment Date",
        col:{ md:3,sm:6 }
    },
    ...mapValues(withPrefix("coupon_",{
        type:{type:"select",label:"Type",
            items:COUPON_TYPE_LIST,[DETAILS_ANNOTATION]:true,
            itemText:"title",itemValue:"const"},
        percent:{...rateInput,label:"Rate",
            [DETAILS_ANNOTATION]:{
                number:{percent:true,suffix:"%"}
            }
        },
        spread:{type:"number",label:"Spread",[DETAILS_ANNOTATION]:true },


    }),x => {
        x['col'] = {md:3,xs:6}
        return x;
    }),
    [defaultValuesKey]:{
        notional_exchange:"B",
        par_value:1,
        periodicity:"A",
        coupon_percent:0,
        coupon_type:"FIX"
    }
}
export const BondDetailsSchemaGrouped = {
    [FlatSchemaKey]:BondDetailsSchema,
    type:"group",
    "group":"tabs",
    schema:{
        basics:{
            type:"wrap",
            title:"Basics",
            schema:pick(BondDetailsSchema,["detail_type","notional_exchange",
            "first_pay_date","last_pay_date"])
        },
        coupon:{
            type:"wrap",
            title:"Coupon Details",
            schema:pick(BondDetailsSchema,[
                "coupon_type","periodicity",
                "coupon_percent","coupon_spread",
                "payable_unit_id"
            ])
        }
    }
}

const safeCapItems =mapValues(  withPrefix("cc_",{
    common:{label:"Common Stock",type:"checkbox"},
    preferred:{ label:"Preferred Stock",type:"checkbox" },
    unissued:{ label:"Unissued Common Stock",type:"checkbox" },
    preferred_unissued:{ label:"Unissued Preferred Stock",type:"checkbox" },
    converts:{label:"Convertable Notes + Other SAFES",type:"checkbox"},
    options:{label:"Options",tooltip:"(Outstanding Vested and Unvested)",type:"checkbox"},
    promised_options:{ label:"Promised Options", type:"checkbox" },
    options_pool:{label:"Options Pool",
        description:"Unissued Options Pool",type:"checkbox"},
    option_pool_increases:{label:"Options Pool Increases",
        tooltip:"Unissued Options Pool Increases",type:"checkbox"},
    warrants:{label:"Warrants",type:"checkbox"}
}),(value:any) => {
    return  {
        'col':{ md:3, sm:6 },
        ...value
    }
});


export const SafeDetailsSchema = {
    valuation_cap:{
        label:"Valuation Cap",type:"number",
        col:{ xs:6 } ,prefix:'$',
        [DETAILS_ANNOTATION]:{
            itemClass:"important",
            number: { compact:true }
        }
    },
    valuation_cap_unit_id:{
        ...instrumentInput,
        label:"Valuation Cap Unit",
        filterType:InstrumentTypes.CCY,
        objectMode:false,
        col:{ xs:6 }
    },
    post_money:{
        label:"Is Post Money",type:"checkbox",
        col:{md:3,xs:6},
        [DETAILS_ANNOTATION]:{
            label:"Valuation Cap Terms",
            transform(x:boolean){
                return x?"Post-Money":"Pre-Money"
            }
        }
    },
    discount: {
        label:"Discount",type:"number",
        [DETAILS_ANNOTATION]:true,
        col:{ md:6 }
    },
    ...safeCapItems,
    [SafeCapitalizationFieldsKey]: Object.keys(safeCapItems)
};





export const SafeDetailsSchemaGrouped  = {
    [FlatSchemaKey] : SafeDetailsSchema,
    "type":"group",
    "group":"tabs",
    schema:{
        details:{
            type:"wrap",
            title:"Details",
            schema: pick(SafeDetailsSchema, [
                "valuation_cap","valuation_cap_unit_id",
                "discount","post_money"
            ])
        },
        capitalization:{
            type:"wrap",
            title:"Capitalization Details",
            description:"Capital Stock checked below are included in the Company Capitalization calculation. Generally Safe Price is the Valuation Cap / Company Capitalization",
            schema: safeCapItems
        }
    }
};

export const RecurDetailsSchema = {
                ...detailType("RECR"),
                recur_type:{
                    label:"Type",
                    type:"select",
                    items:RECUR_TYPE_LIST,
                    itemText:"title",itemValue:"const",
                    col:{ md:3,sm:6 }
                },
    payable_notional:{
        label:"Notional",
        hint:"Notional Amount",
        type:"number",
        col:{xs:6,md:3},
        prefix:"$",
        [DETAILS_ANNOTATION]:{label:"Reference Amount",number:{}}
    },
                payable_unit_id:{
                    label:"Payable Currency",
                    ...instrumentInput,
                    filterType:InstrumentTypes.CCY,
                    hint:"Currency",
                    objectMode:false,
                    [DETAILS_ANNOTATION]:true
                },
                periodicity: {
                    label:"Frequency",
                    ...periodicityInput,
                    col:{ md:3,sm:6 },
                    [DETAILS_ANNOTATION]:true
                },
                coupon_percent:{
                    type:"number",label:"Coupon Percentage",
                    prefix:'%',
                    col:{ md:3,sm:6 },
                    [DETAILS_ANNOTATION]:{
                        number:{percent:true,suffix:"%"}
                    }
                }
}

export const RecurDetailsSchemaGrouped = {
    [FlatSchemaKey]:RecurDetailsSchema,
    type:"group",
    "group":"tabs",
    schema:{
        basics:{
            type:"wrap",
            title:"Amount & Duration",
            schema:pick(RecurDetailsSchema,[
                "detail_type","recur_type","payable_notional",
                "payable_unit_id","periodicity","coupon_percent"
            ])
        }
    },
    [defaultValuesKey]:{
        type:"S"
    }
}

export const MortgageDetailsSchema:SchemaSet = {
    ...detailType("CBND"),
    collateral_id: {
        label:"Collateral",
        ...instrumentInput,
        filterType:InstrumentTypes.REAL,
        objectMode:false,
        hint:"Home / Collateral",
        [DETAILS_ANNOTATION]:true
    },
    payable_notional:{
        label:"Notional",
        hint:"Notional Amount",
        type:"number",
        col:{xs:6,md:3},
        prefix:"$"
    },
    payable_unit_id:{
        label:"Payable Currency",
        ...instrumentInput,
        filterType:InstrumentTypes.CCY,
        hint:"Currency", objectMode:false,
        [DETAILS_ANNOTATION]:true

    },
    fixed_interest_rate:{
        ...rateInput,label:"Interest Rate",
        prefix:'%',
        col:{ md:3,sm:6 },
        [DETAILS_ANNOTATION]:{ number:{precent:true,suffix:"%"}}
    },
    periodicity: {
        label:"Payment Frequency",
        ...periodicityInput,
        col:{ md:3,sm:6 },
        [DETAILS_ANNOTATION]:true

    },
    payment_convention:{
        label:"Payment Type",
        ...interestPaymentConventionInput,
        col:{ md:3,sm:6 },
        [DETAILS_ANNOTATION]:true

    },
    interest_only:{
        label:"Interest Only?",
        type:"checkbox",
        col:{ md:3,sm:6 },
        [DETAILS_ANNOTATION]:true
    },
    mortgage_tax:{
        label: "Mortgage Tax",
        type:"number",
        col: {  md:3, sm:6},
        [DETAILS_ANNOTATION]:{ number:{}}
    },
    appraisal_fee:{
        label: "Appraisal Fee",
        type:"number",
        col: {  md:3, sm:6},
        [DETAILS_ANNOTATION]:{ number:{}}
    },
    credit_report_fee:{
        label: "Credit Report Fee",
        type:"number",
        col: {  md:3, sm:6},
        [DETAILS_ANNOTATION]:true
    },
    amortization_partners:{
        type:"group",
        schema: {
            mode :{
                label:"Amortization Type",
                type:"select",
                items:AMORTIZATION_TYPE_LIST,
                itemText:"title",itemValue:"const",
                [DETAILS_ANNOTATION]:true
            },
            start_amount:{
                label:"Start Amount",
                type:"number",
                [DETAILS_ANNOTATION]:true

            },
            end_amount:{
                label:"End Amount",
                type:"number",
                [DETAILS_ANNOTATION]:true
            },
            start_date:{
                label:"Start Date",
                ...dateInput,
                [DETAILS_ANNOTATION]:true

            },
            end_date:{
                label:"End Date",
                ...dateInput,
                [DETAILS_ANNOTATION]:true
            },
        }
    }

}

export const MortgageDetailsSchemaGrouped = {
    [FlatSchemaKey]:MortgageDetailsSchema,
    type:"group",
    "group":"tabs",
    schema:{
        basics:{
            type:"wrap",
            title:"Duration & Collateral",
            schema:pick(MortgageDetailsSchema,[ "collateral_id" ])
        },
        financing:{
            type:"wrap",
            title:"Financing",
            schema:pick(MortgageDetailsSchema,[
                "payable_notional",
                "payable_unit_id",
                "fixed_interest_rate",
                "periodicity",
                "payment_convention",
                "interest_only"
            ])
        },
        fees:{
            type:"wrap",
            title:"Taxes & Fees",
            schema:pick(MortgageDetailsSchema,[
                "mortgage_tax",
                "appraisal_fee",
                "credit_report_fee"
            ])
        }
    }
}


export const CapitalCommitmentDetailsSchema:SchemaSet = {
    ...detailType("CAPC"),
    fund_id:{
        label:"Fund",
        type:"InputEntity",
        col:{ xs:12,md:3},
        [DETAILS_ANNOTATION]:true,
        [FLOW_ANNOTATION]:{ }
    },
    committed_capital: {
        label:"Committed Capital",
        type:"number",
        col:{xs:12,md:3},
        prefix:"$",
        [DETAILS_ANNOTATION]:{
            itemClass:"important",
            number:{compact:true}
        }
    },
    payable_unit_id:{
        label:"Payable Currency",
        ...instrumentInput,
        filterType:InstrumentTypes.CCY,
        hint:"Currency",
        objectMode:false,
        [DETAILS_ANNOTATION]:true
    },
    underlying_equity_id:{
        label:"Underlying Equity",
        ...instrumentInput,
        objectMode:false,
        filterType:InstrumentType.EQUITY,
        hint:"Partnership Equity",
        [DETAILS_ANNOTATION]:true
    },
    "expenses_short":{
        type:"group",
        label:"Expenses",
        schema:{
            cap:{
                label:"Annual Expense Cap.",
                type:"number",
                [FLOW_ANNOTATION]:{
                    if:'has.expense_cap',
                    step:'commitment',
                    ask:'What is the amount capped by?'
                },
                hint:"For a %-age  please enter  whole value e.g. '10%'",
                col:COL_FULL_HALF_THIRD,
            },
            cap_type:{
                label:"Cap. Type",
                type:"select",
                items:FIXED_OR_PERCENTAGE_LIST,
                itemText:"title",itemValue:"const",
                col:{md:2,sm:6,xs:12}
            },
            payable_unit_id: {
                label:"Cap. Payable Unit",
                ...instrumentInput,
                objectMode:false,
                filterType:InstrumentTypes.CCY,
                [FLOW_ANNOTATION]:{
                    step:'commitment',
                    ask:'What unit'
                }
            }
        }
    },
    "fees_short":{
        type:"group",
        label:"Fees",
        schema: {
            management_fee: {
                ...rateInput,
                col:COL_FULL_HALF_THIRD,
                label:"Management Fee (Annualized)",
                [DETAILS_ANNOTATION]:{
                    number:{percent:true,suffix:"%"}
                },
                [FLOW_ANNOTATION]:{
                    step:'commitment',
                    ask:'What % Fees does the management receive over time?'
                },
            },
            management_notional_target: {
                ...notionalTargetInput,
                col:COL_FULL_HALF_THIRD,
                label:"Management Notional Target",
                [FLOW_ANNOTATION]:{
                    step:'management.fees',
                    ask:'Is the management fee based on Capital Committed or Called Capital?'
                }
            },
            management_fee_end_date:{
                type:"text",
                label:"Fund Target End Date",
                ext:"date",
                col:{xs:12,md:3},
                [DETAILS_ANNOTATION]:{
                }
            },
            carried_interest:{
                ...rateInput ,
                label:"Carried Interest Rate",
                col:COL_FULL_HALF_THIRD,
                [DETAILS_ANNOTATION]:{
                    label:"Performance Fee",
                    number:{percent:true,suffix:"%"}
                },
                [FLOW_ANNOTATION]:{
                    step:'performance.fees',
                    ask:"What is the GP's profit share or carried intereest rate?",
                }
            },
            carried_interest_notional_target: {
                ...notionalTargetInput,
                col:COL_FULL_HALF_THIRD,
                label:"Carried Interest Notional Target",
                [FLOW_ANNOTATION]:{
                    step:'management.fees',
                    ask:'Is the management fee based on Capital Committed or Called Capital?'
                }
            },
            carried_interest_end_date:{
                type:"text",
                label:"Fund Fee End Date",
                ext:"date",
                [DETAILS_ANNOTATION]:{
                }
            },
            hurdle_rate: {
                ...rateInput,
                label:"Hurdle/Preferred Return Rate",
                [DETAILS_ANNOTATION]:{
                    number:{percent:true,suffix:"%"}
                },
                [FLOW_ANNOTATION]:{
                    'if':'has.hurdle_rate',
                    step:'performance.fees',
                    ask:"What is the Hurdle Rate?"
                },
                col:COL_FULL_HALF_THIRD,
            },
            general_partner_id: {
                type:"InputEntity",
                label:'General Partner',hint:"General Partner Entity",
                col:{xs:12,md:6},
                [DETAILS_ANNOTATION]:true,
                [FLOW_ANNOTATION]:{
                    step:"commitment",
                    ask:"Who"
                }
            }

        }
    }
}



export const CapitalCommitmentDetailsSchemaGrouped = {
    [FlatSchemaKey]:CapitalCommitmentDetailsSchema,
    type:"group",
    "group":"tabs",
    schema :{
        basics: {
            type:"wrap",
            title:"Commitment",
            schema: pick(CapitalCommitmentDetailsSchema,[
                "detail_type","committed_capital","payable_unit_id","fund_id","underlying_equity_id","end_date"
            ])
        },
        fees_simple:{
            type:"wrap",
            title:"Fees (Simple)",
            schema: pick(CapitalCommitmentDetailsSchema,["fees_short"])
        },
        expenses : {
            type:"wrap",
            title:"Expenses",
            schema: pick(CapitalCommitmentDetailsSchema,["expenses_short"]),
        }


    },
}




const DetailSchemaCache:Record<string,ExpandedSchema| null> = {}

export function rawSchemaForType(instType:InstrumentType):any {
    switch(instType){
        case "BOND": return BondDetailsSchemaGrouped
        case "EQTY": return EquityDetailsSchemaGrouped
        case "REAL": return RealEstateDetailsSchemaGrouped
        case "SAFE": return SafeDetailsSchemaGrouped
        case "RECR": return RecurDetailsSchemaGrouped
        case "CBND": return MortgageDetailsSchemaGrouped
        case "CAPC": return CapitalCommitmentDetailsSchemaGrouped
    }
    return null;
}
export function flatSchemaForType(instType:InstrumentType):any {
    let first = rawSchemaForType(instType);
    if(first && FlatSchemaKey in first ){
        return first[FlatSchemaKey]
    }
    return first;
}
export async function detailSchemaForAsset(instType:InstrumentType,
    $axios:Context['$axios']):Promise<any>{
       var existing = DetailSchemaCache[instType]
        if(typeof existing === "undefined"){
            let schema =rawSchemaForType(instType)
            existing = DetailSchemaCache[instType] = schema?(new ExpandedSchema(schema,$axios)):null
        }
        return existing?.value
    }


export type CreatableInstrumentRef = InstrumentRef & {
    isCreateEntry?:boolean
}


export interface InputTransaction {
    what:CreatableInstrumentRef
    when:string|Date
    name?:string
    target_account?:AccountRef
    counterparty_account?:AccountRef
    asset_type:InstrumentType
    counterparty:string
    direction:components["schemas"]["Direction"]
    price_unit:string|InstrumentRef
    fair_market_value:number
    fair_market_value_unit:InstrumentRef
    units: number
    price:number
    instrument_details?:any;
    amendment_time?:string|Date
    last_update_token?:string
    notes?:string
    id?:string //Transaction UUID
}


// Event that is a NewTransaction
export type InputTransactionEvent = InputApiEvent  & {
    id?:string
    new_transaction:InputApiNewTransactionEvent
}






export type TransactionSchema  = typeof TRANSACTION_SCHEMA
export default TRANSACTION_SCHEMA

