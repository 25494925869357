import {isObject} from "lodash";



export type CompletablePromise<T> = Promise<T> & {
    resolve(x:T):void
    reject(x:Error):void
}

/*
 * A Function that returns a completable future
 */
export function completable<T>() {
    let resolve!:(x:T) => void;
    let reject!:(x:Error) =>  void;
    return Object.assign(new Promise<T>((r1,r2) => {
        resolve=r1
        reject=r2
    }),{resolve,reject})
}


export function isPromiseLike(x:any): x is Promise<any>{
  if(!x) return false
  if(typeof x !== 'object') return false;
  return typeof x.then === 'function' && typeof x.catch === 'function'
}
