/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FileInputCsvConfig = {
    description: `CSV File Format Specification`,
    properties: {
        quote: {
            type: 'string',
        },
        delim: {
            type: 'string',
        },
        escape: {
            type: 'string',
        },
        newline: {
            type: 'string',
        },
        noHeader: {
            type: 'boolean',
        },
        auto_detect: {
            type: 'boolean',
        },
    },
} as const;
