/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataJoinVisual = {
    description: `A visual Join stage is a join stage,
    that joins a  piece of data from any kind of stage
    directly with columns from an input processing stage
    that edits data that is inline to the workflow.`,
    properties: {
        scalars: {
            type: 'array',
            contains: {
                type: 'JoinVisualScalar',
            },
        },
        support_columns: {
            type: 'array',
            contains: {
                type: 'JoinVisualColumn',
            },
        },
        columns: {
            type: 'array',
            contains: {
                type: 'JoinVisualColumn',
            },
            isRequired: true,
        },
        header: {
            type: 'EtlVisualBlock',
        },
        footer: {
            type: 'EtlVisualBlock',
        },
    },
} as const;
