/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiEtlRunRequest = {
    properties: {
        stop_at: {
            type: 'string',
        },
        first_run: {
            type: 'boolean',
        },
    },
} as const;
