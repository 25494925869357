import {PropType} from "vue"
import {defineComponent,CreateElement,VNode,h} from "vue"

const DetailItem = defineComponent({
  props:{
    label:{type:String as PropType<string>,required:true }
  },
  setup(props,{slots}){
    return () => {
      return h("div",{ "class":["text-no-wrap","overflow-x-hidden",
               "text-truncate"]},
               [h("strong",{},[props.label, " : "]),
                 ...(slots.default?.()||[])])
    }
  }
})


export default DetailItem
