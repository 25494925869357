/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ConstantColumn = {
    properties: {
        value: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'uuid',
            }, {
                type: 'number',
            }, {
                type: 'number',
            }, {
                type: 'string',
                format: 'date-time',
            }, {
                type: 'string',
                format: 'date',
            }, {
                type: 'string',
            }],
            isRequired: true,
        },
        row: {
            type: 'number',
        },
        column: {
            type: 'string',
        },
        columnIndex: {
            type: 'number',
        },
    },
} as const;
