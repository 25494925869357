/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FullInstrumentRef = {
    description: `This is a reference to an existing instrument in the system.
     *OR*
    Sufficient information to indicate a new one should be created.`,
    properties: {
        type: {
            type: 'InstrumentRefType',
            isRequired: true,
        },
        id: {
            type: 'one-of',
            contains: [{
                type: 'string',
                format: 'uuid',
            }, {
                type: 'number',
            }, {
                type: 'string',
            }],
            isRequired: true,
        },
        start_date: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date',
            }, {
                type: 'string',
                format: 'date-time',
            }],
        },
        end_date: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date',
            }, {
                type: 'string',
                format: 'date-time',
            }],
        },
        issuer: {
            type: 'EntityRef',
        },
        issue_date: {
            type: 'string',
            format: 'date',
        },
        details: {
            type: 'one-of',
            contains: [{
                type: 'EquityDetails',
            }, {
                type: 'SafeDetails',
            }, {
                type: 'BondDetails',
            }, {
                type: 'RealEstateDetails',
            }, {
                type: 'CcyDetails',
            }, {
                type: 'OtherDetails',
            }, {
                type: 'MortgageDetails',
            }, {
                type: 'RecurDetails',
            }, {
                type: 'CapitalCommitmentDetails',
            }],
        },
        label: {
            type: 'string',
        },
        updates: {
            type: 'InstrumentUpdates',
        },
    },
} as const;
