/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataResolvedField = {
    properties: {
        input_ref: {
            type: 'one-of',
            contains: [{
                type: 'string',
                pattern: '[a-zA-Z0-9\\._\\-]+#[a-zA-Z0-9\\.\\-_]+(@[a-zA-Z0-9_]+)?',
            }, {
                properties: {
                    stage: {
                        type: 'string',
                        isRequired: true,
                    },
                    name: {
                        type: 'string',
                        isRequired: true,
                    },
                    alias: {
                        type: 'string',
                    },
                },
            }],
            isRequired: true,
        },
        field: {
            type: 'InputField',
            isRequired: true,
        },
    },
} as const;
