/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiCustomEtlRun } from '../models/ApiCustomEtlRun';
import type { ApiEtlRunRequest } from '../models/ApiEtlRunRequest';
import type { ApiWorkflowInputSchema } from '../models/ApiWorkflowInputSchema';
import type { BatchRequest_ApiWorkflowInputUpdate_ } from '../models/BatchRequest_ApiWorkflowInputUpdate_';
import type { DataLookupExpanded } from '../models/DataLookupExpanded';
import type { ImportMappingIdea } from '../models/ImportMappingIdea';
import type { ListResponse_Union_Literal_None___list_distributary_api_io_ErrorEntry___ } from '../models/ListResponse_Union_Literal_None___list_distributary_api_io_ErrorEntry___';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EtlService {

    /**
     * Start Run
     * Start a new Workflow Run
     * @param ulid
     * @param host
     * @param requestBody
     * @returns ApiCustomEtlRun Successful Response
     * @throws ApiError
     */
    public static startRun(
        ulid: string,
        host?: string,
        requestBody?: ApiEtlRunRequest,
    ): CancelablePromise<ApiCustomEtlRun> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/etl/start',
            path: {
                'ulid': ulid,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Schedule Run
     * Schedule a Workflow Run
     * @param ulid
     * @param runId
     * @param host
     * @param requestBody
     * @returns ApiCustomEtlRun Successful Response
     * @throws ApiError
     */
    public static scheduleRun(
        ulid: string,
        runId: string,
        host?: string,
        requestBody?: ApiEtlRunRequest,
    ): CancelablePromise<ApiCustomEtlRun> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/etl/{runId}/schedule',
            path: {
                'ulid': ulid,
                'runId': runId,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Schemas
     * Get the fully defined schema choices for a workflow
     * @param typeId
     * @param stageId
     * @param host
     * @returns ApiWorkflowInputSchema Successful Response
     * @throws ApiError
     */
    public static getSchemas(
        typeId: string,
        stageId: string,
        host?: string,
    ): CancelablePromise<Array<ApiWorkflowInputSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workflow-types/{typeId}/etl/{stageId}/schemas',
            path: {
                'typeId': typeId,
                'stageId': stageId,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Mappings
     * Get the fully defined schema choices for a workflow
     * @param typeId
     * @param stageId
     * @param schemaId
     * @param host
     * @returns ImportMappingIdea Successful Response
     * @throws ApiError
     */
    public static getMappings(
        typeId: string,
        stageId: string,
        schemaId?: string,
        host?: string,
    ): CancelablePromise<Array<ImportMappingIdea>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workflow-types/{typeId}/etl/{stageId}/mappings',
            path: {
                'typeId': typeId,
                'stageId': stageId,
            },
            headers: {
                'host': host,
            },
            query: {
                'schemaId': schemaId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Stop Run
     * @param ulid
     * @param runId
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static stopRun(
        ulid: string,
        runId: string,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/etl/{runId}/stop',
            path: {
                'ulid': ulid,
                'runId': runId,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Status
     * @param ulid
     * @param runId
     * @param host
     * @returns ApiCustomEtlRun Successful Response
     * @throws ApiError
     */
    public static getStatus(
        ulid: string,
        runId: (string | 'latest'),
        host?: string,
    ): CancelablePromise<ApiCustomEtlRun> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workflows/{ulid}/etl/{runId}/status',
            path: {
                'ulid': ulid,
                'runId': runId,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Output
     * @param ulid
     * @param runId
     * @param stageId
     * @param outputName
     * @param fmt
     * @param persistent
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getOutput(
        ulid: string,
        runId: string,
        stageId: string,
        outputName: (number | string),
        fmt: ('doc' | 'json' | 'json+split' | 'csv' | 'xlsx'),
        persistent: boolean = false,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/etl/{runId}/result',
            path: {
                'ulid': ulid,
                'runId': runId,
            },
            headers: {
                'host': host,
            },
            query: {
                'stageId': stageId,
                'outputName': outputName,
                'fmt': fmt,
                'persistent': persistent,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cache Stage Output
     * @param ulid
     * @param runId
     * @param stageId
     * @param identifier
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cacheStageOutput(
        ulid: string,
        runId: string,
        stageId: string,
        identifier: string,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/workflows/{ulid}/etl/{runId}/store',
            path: {
                'ulid': ulid,
                'runId': runId,
            },
            headers: {
                'host': host,
            },
            query: {
                'stageId': stageId,
                'identifier': identifier,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Ui State
     * @param ulid
     * @param stateId
     * @param requestBody
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateUiState(
        ulid: string,
        stateId: string,
        requestBody: any,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/etl/ui-state',
            path: {
                'ulid': ulid,
            },
            headers: {
                'host': host,
            },
            query: {
                'stateId': stateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Input
     * @param ulid
     * @param requestBody
     * @param host
     * @returns ListResponse_Union_Literal_None___list_distributary_api_io_ErrorEntry___ Successful Response
     * @throws ApiError
     */
    public static updateInput(
        ulid: string,
        requestBody: BatchRequest_ApiWorkflowInputUpdate_,
        host?: string,
    ): CancelablePromise<ListResponse_Union_Literal_None___list_distributary_api_io_ErrorEntry___> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/input/update',
            path: {
                'ulid': ulid,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Resolve Lookup
     * @param ulid
     * @param stageId
     * @param host
     * @returns DataLookupExpanded Successful Response
     * @throws ApiError
     */
    public static resolveLookup(
        ulid: string,
        stageId: string,
        host?: string,
    ): CancelablePromise<DataLookupExpanded> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/meta/resolve-lookup',
            path: {
                'ulid': ulid,
            },
            headers: {
                'host': host,
            },
            query: {
                'stageId': stageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
