/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $VisualComputedColumn = {
    description: `    `,
    properties: {
        expression: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
