/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiEvent = {
    description: `Api Event. We will strengthen some of the values`,
    properties: {
        event_type: {
            type: 'EventType',
            isRequired: true,
        },
        description: {
            type: 'string',
            maxLength: 100,
        },
        identifier: {
            type: 'string',
        },
        instant: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date',
            }, {
                type: 'string',
                format: 'date-time',
            }],
            isRequired: true,
        },
        parent_event_id: {
            type: 'string',
            format: 'uuid',
        },
        new_transaction: {
            type: 'ApiNewTransactionEvent',
        },
        capital_call: {
            type: 'ApiCapitalCallEvent',
        },
        capital_distro: {
            type: 'ApiCapitalDistroEvent',
        },
        capital_activity: {
            type: 'ApiCapitalActivityEvent',
        },
        event_data: {
            type: 'EventData',
        },
        advisor_note: {
            type: 'string',
        },
        acl: {
            type: 'ApiPermissions',
        },
        settlement_notes: {
            type: 'array',
            contains: {
                type: 'InputApiSettlementAccountEntry',
            },
        },
        notes: {
            type: 'string',
        },
        prospect_id: {
            type: 'string',
            format: 'uuid',
        },
        import_facet: {
            type: 'ImportFacet',
        },
        id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        user_tags: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        user_attributes: {
            properties: {
            },
        },
        root_event_id: {
            type: 'string',
            format: 'uuid',
        },
    },
} as const;
