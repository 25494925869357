import gql from "graphql-tag"
import { ApiInstrument, ApiObservable,InputApiObservable, InstrumentRef, ObservableField, ObservableSummaryItem } from "~/schemas/gen"
import { Observable_Data,Observable_Data_Bool_Exp } from "~/models/graphql"

export type ObservableDatum = Partial<Observable_Data>;

export type ApiObservableDataNode  = Partial<ApiObservable> & {
  type?: Partial<{
    name:string
    identifier:string
  }>
}

export const fragApiObservableDataFields = gql`
fragment ApiObservableFields on observable_data {
    ulid identifier fields price quantity value instant
    updated_time description  date_of
    type:observable_type {
        name identifier
    }
    units {
        uuid instrument_type
    }
    instrument {
        display_name uuid identifier_type instrument_type
    }
}
`


export type SymMappingObservable = InputApiObservable & {
    is_create?:boolean
    from_holding?:boolean
    is_search?:boolean
    search_term?:string
}



//Return the  unique id for a symbol mapping entry
export function symbolMappingIdentity(x:ApiObservable|InputApiObservable){
    return [x.identifier,
        x.fields?.[ObservableField.SYM_ALT_NAME] ?? "",
        x.fields?.[ObservableField.SYM_NAME_ONLY] ?? false,
        x.fields?.[ObservableField.SYM_URL] ?? false,
        x.description ?? ""
    ].join("\0")
}


export type SummaryItemKey = Pick<ObservableSummaryItem,"date_of"|"src_label">

export function summaryItemIdentity(x:SummaryItemKey):string|undefined{
    if(x.src_label) return x.date_of + "\0" + x.src_label;
    return x.date_of
}

export function parseSummaryItemIdentity(x:string|undefined):SummaryItemKey|undefined {
    if(!x) return
    let parts = x.split("\0",2)
    return {
        date_of:parts[0],
        src_label:parts[1]
    }
}
