/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FileInputEntry = {
    properties: {
        item: {
            type: 'any-of',
            contains: [{
                type: 'FileDocumentInput',
            }, {
                type: 'FileInlineInput',
            }],
            isRequired: true,
        },
        id: {
            type: 'string',
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
        mapping: {
            type: 'any-of',
            contains: [{
                type: 'Enum',
            }, {
                type: 'FileRefMapping',
            }, {
                type: 'FileInlineMapping',
            }],
            isRequired: true,
        },
        output_schema: {
            type: 'string',
            pattern: '[A-Za-z][A-Za-z0-9_]*',
        },
        skip: {
            type: 'number',
        },
        csvConfig: {
            type: 'FileInputCsvConfig',
        },
        name: {
            type: 'string',
            pattern: '[A-Za-z][A-Za-z0-9_]*',
        },
    },
} as const;
