
import {
  ApiDocument,
    DocumentsService,
    InputApiDocument,InputApiDocumentLink
} from "~/schemas/gen"
import {UploadJob,UploadJobState} from "~/store/documents"
import {Component,Vue,namespace} from "nuxt-property-decorator"
import {ref} from "vue"


const documentNs = namespace('documents')

type WithFlatTags = {
    flat_tags:string[] // Manadatory in this forumation
}
export type InputDocWithFile = InputApiDocument & WithFlatTags &  {
    file:File
    job?:UploadJob
}

export type InputDocWithTags  = InputApiDocument & WithFlatTags & {
  is_linked:boolean
  to_unlink:boolean
  link_error:any
}


export type IUploadLinkTarget  =Omit<InputApiDocumentLink,"uuid"|"attributes">;

export interface IWithDocuments {
    documents:InputDocWithFile[]
    existing_documents:InputDocWithTags[]
}
export interface IDocumentProvider extends IWithDocuments {
}

export interface ISubmitEvents {
    onError(error:Error,doc?:InputDocWithFile):void
    onSuccess(doc:InputDocWithFile):void
}


export function makeDocument(f:File,tags:string[]=[]):InputDocWithFile{
    return{
        name:f.name, storage_size: f.size,
        file:f, flat_tags:tags,
        job:undefined
    }
}

export function useDocumentProvider(){
  const documents = ref<InputDocWithFile[]>([])
  const existingDocuments = ref<InputDocWithTags[]>([])
  const provider:IDocumentProvider = {
    get documents(){
      return documents.value;
    },
    set documents(value){
      documents.value=value
    },
    get existing_documents(){
      return existingDocuments.value
    },
    set existing_documents(value){
      existingDocuments.value = value
    }
  }
  return {provider,documents,existingDocuments}
}



export function mergeDocuments(items:IWithDocuments['documents'],
                        files:File[],
                        tags:string[]):IWithDocuments['documents']{
    let out = [...items]
    for(let f of files){
        out.push(makeDocument(f,tags))
    }
    return out;
}




/**
 * Render less component that handles file uploads
 */
@Component({})
export class WithDocumentUpload extends Vue {
    @documentNs.Action('startUpload') startUpload!:any
    @documentNs.Action('waitForJob') waitForJob!:(id:number) => Promise<UploadJob>;
    @documentNs.Action('getJob') getUploadJob!:(id:number) => Promise<UploadJob>;
    provider!:IDocumentProvider
    uploading:boolean = false

    withProvider(p:IDocumentProvider):WithDocumentUpload{
        this.provider =p;
        return this
    }
    get documents():(InputDocWithFile|InputDocWithTags)[]{
      let {documents:d1,existing_documents:d2} = this.provider;
      if(d1.length > 0){
        if(d2.length > 0 ){
          return d1.concat(d2 as any)
        }
        return d1
      }
      return d2
    }

    isUploadReady(payload:IWithDocuments,tags?:string[]){
        if(!tags || tags.length  ==0) return payload.documents.length >0 ;
        for (let x of payload.documents){
            if(!x.flat_tags) continue;
            for(let t of tags){
                if(x.flat_tags.indexOf(t)!= -1) return true;
            }
        }
        return false;
    }

    // Remove file from document list
    removeFile(item:InputDocWithFile|InputApiDocument,
               target?:IWithDocuments){
        target=  target ?? this.provider;
        let removeDoc = (x:typeof item) => {
            return x !== item;
        }

        target.documents =  target.documents.filter(removeDoc)
        target.existing_documents = target.existing_documents.filter(removeDoc)
    }

    clear(){
        this.provider.documents = []
        this.provider.existing_documents = []
    }

    //Filter Document tags
    filterDocumentsByTags(tags:string[]){
        return this.documents.filter((x) => {
            return x.flat_tags.filter(t =>  tags.includes(t)).length > 0;
        })
    }

    getDocumentJob(doc:InputDocWithFile):UploadJob|undefined {
        for(let d of this.provider.documents || []){
            if(d === doc) return d.job
        }
        return;
    }

    //Handle a droipped file across all the upload views
    acceptDocumentUpload(items:File|FileList,
                         tags:string[]|undefined,target?:IWithDocuments){
        let files!:File[]
        if(items instanceof FileList){
            files = Array.from(items)
        }else {
            files = [items]
        }
        let tgt=(target ||this.provider);
        tgt.documents = mergeDocuments(tgt.documents,files,tags || [])

    }

    addExistingDocument(d:InputApiDocument,tags:string[]|undefined){
      //Check to see if document is in there already
      let foundIdx = this.provider.existing_documents.findIndex(x =>  x.uuid === d.uuid )
      if(foundIdx != -1) return
      this.provider.existing_documents.push({...d,
                                            is_linked:false,
                                            to_unlink:false,
                                            link_error:false,
                                            flat_tags:tags || []})
    }


    setUploadingState(newState:boolean){
        this.uploading = newState;
    }

    /**
     * Start the process of uploading documents
     */
    async startSubmissionUpload(targets:IUploadLinkTarget[],events?:ISubmitEvents):Promise<boolean>{
        //We are a the end of the line so we will submit the documents
        let {documents} = this.provider;
        try{
            this.setUploadingState(true)
            let failed= 0;
            for(let d of documents){
                if(d.job && d.job.response){
                    if(d.job.state == UploadJobState.Completed)  continue;
                }
                let input:InputApiDocument = {...d,link_updates:{adds:[]}}
                // If thi
                /* if(this.value.prospect_id){
                    input.link_updates!.adds?.push({prospect:this.value.prospect_id})
                }else if(this.editor.model?.id){
                    let docId =  this.editor.model!.id!
                    input.link_updates!.adds!.push({transaction:docId})
                }*/
                input.link_updates!.adds!.push(...targets)
                if(d.uuid){
                  //If there is an existing document we will  not upload it
                  //@TODO: Handle document linkage
                  continue;
                }
                let jobId = await this.startUpload({input,file:d.file }).catch((err:Error) =>err);
                if(jobId instanceof Error){
                    events?.onError?.(jobId,d);
                    console.log("job Error!",jobId);
                    continue
                }
                //Wait for the job to complete
                let job = d.job = await this.waitForJob(jobId!);
                if(job.error){
                    failed += 1
                    continue
                }
                events?.onSuccess(d)
            }
            if(failed > 0){
                return false
            }
            return true
        }catch(err:any){
            console.log("Submission Error!",err)
            events?.onError(err)
            return false;
        }finally {
            this.setUploadingState(false);
        }
    }


    //Request Documents to be linked
    async startSubmissionLinking(target:IUploadLinkTarget,toLink:InputDocWithTags[]):Promise<boolean>{
      let linkErrors = 0
      const jobs = toLink.map(d =>  DocumentsService.updateDocument(d.uuid!,{link_updates:{adds:[target]}}));
      (await Promise.allSettled(jobs)).map((x,idx) => {
        if(x.status == "rejected"){
          linkErrors += 1
          toLink[idx].link_error = x.reason
        }else {
          toLink[idx].is_linked = true;
        }
      })
      return linkErrors == 0
    }

    uploadSubmitDetails(payload:IWithDocuments,tags?:string[]){
      let parts:string[] = []
      let docs = (tags?.length)?this.filterDocumentsByTags(tags!):this.documents
      console.log("Documents ->",docs)
      let toUpload = docs.filter(x => ("job" in x)  && x.job?.state != UploadJobState.Completed)
      let toLink =  docs.filter((x:any) => !("job" in x) && !x.is_linked)
      if(toUpload.length > 0){
        const n = toUpload.length
        parts.push(`Upload ${n} Document` + ((n>1)?"s":""))
      }
      if(toLink.length > 0){
        const n = toLink.length
        parts.push(`Link ${n} Document` + ((n>1)?"s":""))
      }
      if(parts.length == 0) parts.push("Upload Documents")

      return {
        label:parts.join(" & "),
        ready:  this.isUploadReady(payload,tags) || (toLink.length > 0),
        toLink,toUpload
      }
    }

}
