/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $VisualColumnGrouping = {
    properties: {
        identifier: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
        },
        column_template: {
            properties: {
            },
        },
    },
} as const;
