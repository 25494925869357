/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiCustomEtlRun = {
    properties: {
        ulid: {
            type: 'string',
            isRequired: true,
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
        workflow_id: {
            type: 'string',
            isRequired: true,
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
        creator_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        status: {
            type: 'EtlStageStatus',
            isRequired: true,
        },
        config_timestamp: {
            type: 'string',
            format: 'date-time',
        },
        states: {
            type: 'dictionary',
            contains: {
                type: 'ApiEtlStageState',
            },
        },
        ordering: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        failed_stages: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        review_stages: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        is_config_stale: {
            type: 'boolean',
        },
        parent: {
            type: 'string',
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
    },
} as const;
