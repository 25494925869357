import {AxiosResponse} from "axios"
import  Vue,{ref,Ref,reactive,watch,markRaw} from "vue"
import {mapValidationErrors, MapValidationErrorsOpts, ValidationErrorMap} from "~/utils/validation"
import {ApiError} from "~/schemas/gen"
import {useNotifier} from "~/plugins/notifier.client"

export class MissingModelError extends Error{}
export class InvalidModelError extends Error{}

export const ERR_ALREADY_MODIFIED = "value_error.already_modified"
export type ErrorHandler=(resp:Error|AxiosResponse,data:any)=>boolean|void;

export interface DeleteDialog {
    opened:boolean
    content:any
    loading:boolean
}


export class ErrorStateBase<ErrorStateT> {
  validationErrors_!:Ref<ValidationErrorMap>
  errorState_!:Ref<ErrorStateT|false>

  constructor(errors:ValidationErrorMap){
    this.validationErrors_ = ref(errors)
    this.errorState_ = ref(false)
    markRaw(this)
  }

  get validationErrors(){
    return this.validationErrors_.value
  }
  get errorState(){
    return this.errorState_.value
  }
  set validationErrors(x) {
    this.validationErrors_.value = x
  }

  set errorState(x) {
    this.errorState_.value = x
  }

  clearErrors(){
    this.validationErrors_.value = {};
    this.errorState_.value = false;
  }

  async maybeHandleError(resp:Error|AxiosResponse,handler?:ErrorHandler):Promise<boolean>{
    if(resp instanceof Error){
      let {response} = resp as any
      let body:any = response?.data
      if(resp instanceof ApiError){
        body =resp.body
        response=resp;
      }

      if(handler && typeof(handler(resp,body)) !== "undefined") return true;
      const $notifier = useNotifier()
      if(response){
        //Response status 422
        if(response && response.status ==422){
          //Validation Errors
          let data= await body;
          this.validationErrors_.value =  mapValidationErrors(data.detail)
          $notifier?.error?.("There are a few errors...")
          return true
        }
      }

      $notifier?.error?.("Request Failed:" +resp.message);
      return true
    }
    return false
  }

  async addImpl<T,K>(model:any,func:(model:T) =>Promise<K>, handler?:ErrorHandler) :Promise<K> {
    this.clearErrors()
    if(!model) {
      throw new MissingModelError()
    }
    let toAdd = { ...model }
    let resp = await func(toAdd).catch((err:Error) => err)
    if(resp instanceof Error){
      await this.maybeHandleError(resp,handler)
      throw resp
    }
    return resp
  }


  //Update an entity
  async updateImpl<T,K>(id:string,model:T,
                        func:(id:string,model:T)=>Promise<K>,
                          errorHandler?:ErrorHandler):Promise<K> {
                          this.clearErrors();
                          if(!model) throw new MissingModelError()
                          let toAdd = { ...model }
                          let resp!:K|Error ;
                          try {
                            resp = await func(id,model).catch((err:Error) => err)
                          }catch(ex){
                            resp = ex as Error;
                          }
                          if(resp instanceof Error) {
                            console.log("Got Error ->",resp,resp instanceof Error,this.maybeHandleError)
                            await this.maybeHandleError(resp,errorHandler);
                            throw resp
                          }
                          return resp
                        }
}


type StringPredicate = (x:string)=>boolean


export class WithErrors {
  errors_:Ref<ValidationErrorMap>
  timestamp_:Ref<Date>

  constructor(){
    this.errors_ =  ref({})
    this.timestamp_ = ref(new Date())
  }

  get errors(){ return this.errors_.value}
  set errors(x) {this.errors_.value = x}
  get timestamp() { return this.timestamp_.value}
  set timestamp(x) { this.timestamp_.value =x }

  /**
   * We want to wire a withErrors object to a ErrorState object
   */
  static fromErrorState<T>(state:ErrorStateBase<T>,cm:Vue):WithErrors{
      let errs = new WithErrors();
      watch(() => state.validationErrors ,(x:any) => {
        errs.errors = x
      })
      return errs;
  }
  static fromErrors(errors:Ref<ValidationErrorMap|undefined>):WithErrors{
      let errs = new WithErrors();
      watch(errors,(x:any) => {
        errs.errors = x
      })
      return errs;
  }

  static useErrors():[WithErrors,(x:Record<string,any>)=>void] {
    let errs = new WithErrors();
    return [errs,(upd:any) => {
      errs.errors = upd;
      errs.timestamp = new Date();
    }]
  }


  //Get error array for a field
  getErrorMessage(fullPath:string|StringPredicate):string[] {
    if(fullPath instanceof Function){
      return Object.entries(this.errors ?? {})
      .filter(([k,_]) => fullPath(k))
      .flatMap(([_,v]) => v.map(x => x.msg))
    }
    let errs = this.errors?.[fullPath] || [];
    if(!Array.isArray(errs)) errs = [errs]
    return errs.map(x => x.msg)
  }
  //Same method
  getErrorMessages(...paths:(StringPredicate|string)[]):string[]{
    return paths.flatMap(x => this.getErrorMessage(x))
  }
  //Add Error message for a field to anmn object @ `errorMessages`
  addErrorMessages(fullPath:string,target:Record<string,any>){
    let msgs = this.getErrorMessage(fullPath);
    if(msgs.length > 0) {
      target.errorMessages = msgs
    }
  }
  //Clear Error Messages
  clearErrorMessages(fullPath:string){
    if(!this.errors) return;
    delete this.errors[fullPath]
  }
  /**
   * Process response as if it is a validation error extract the messagesfrom it
   */
  static async maybeHandleError(resp:Error|AxiosResponse,handler?:ErrorHandler,
                                opts?:Partial<MapValidationErrorsOpts>):Promise<boolean|ValidationErrorMap>{
    if(resp instanceof Error){
      let {response} = resp as any
        let body:any = response?.data
        if(resp instanceof ApiError){
          body =resp.body
            response=resp;
        }

  if(handler && typeof(handler(resp,body)) !== "undefined") return true;
  if(response){
    //Response status 422
    if(response && response.status ==422){
      //Validation Errors
      let data= await body;
      return mapValidationErrors(data.detail,opts)
    }
  }
  return true
}
return false
    }
}

/**
 * Construct a full path to an element
 */
export function makeFullPath(name:string,path:string[]):string {
    let fullPath =   path.join(".")
    if(fullPath.length > 0)return fullPath  + "." + name;
    return name;
}


