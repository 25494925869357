
import Vue,{CreateElement,VNode,RenderContext,defineComponent,ref,computed, useAttrs, useListeners,h} from "vue"
import {VIcon} from "vuetify/lib"
import {PropType} from "vue"


export default defineComponent({
  name:"PreviewPanel",
  components:{VIcon},
  props:{
    id:{type:String as PropType<string>,required:true},
    size:{type:Number as PropType<number>,default:512}
  },
  setup(props){
    const $attrs = useAttrs()
    const $listeners = useListeners()
    const error = ref<Error|null>(null);
    function getUrl(){
      return `/api/v1/documents/${props.id}/thumbnail/?size=${props.size}`
    }
    return (h:CreateElement) => {
      var imageUrl = getUrl();
      let imgChild!:VNode;
      if(error.value || !props.id){
        imgChild = h(VIcon,{class:["large","py-10"],props:{size:50}},"far file")
      }else{
        imgChild = h("img",{
          domProps:{
            src:imageUrl
          },on:{
            error: (err:Error) => {
              error.value = err
            }
          }
        })
      }
      return h("div",{
        class:["preview-panel"],
        style:{
        },
        props:{...$attrs},
        on:{...$listeners}
      },[
        imgChild
      ])
    }
  }
})

