/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataFilterConfig = {
    properties: {
        expression: {
            type: 'string',
            isRequired: true,
        },
        lang: {
            type: 'all-of',
            contains: [{
                type: 'ExpressionLanguage',
            }],
        },
    },
} as const;
