/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputEtlStageState = {
    properties: {
        state: {
            type: 'FileInputStageState',
        },
        ui_state: {
            properties: {
            },
        },
    },
} as const;
