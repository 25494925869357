/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $OtherDetails = {
    properties: {
        detail_type: {
            type: 'Enum',
        },
    },
} as const;
