
import {Component,Vue,Inject,Prop,Watch,ModelSync} from "nuxt-property-decorator"
import debounce from "lodash/debounce"
import { getCurrencies } from "~/schemas/enrichment"
import { InstrumentTypes,CreatableInstrumentRef } from "~/schemas/transaction_schemas"
import { genericNamedInstrument } from "~/core/transactions"
import {VNode,CreateElement, nextTick} from "vue"
import {VChip,VIcon,VHover} from "vuetify/lib"
import {ApiEntityNode} from "~/core/entity"
import InputContext  from "~/utils/InputContext"
import gql from "graphql-tag"
import {
    InstrumentRef,InstrumentType,InstrumentRefType,
    ApiInstrument,InstrumentService,
    DataService,InstrumentListRequest
} from "~/schemas/gen"
import {ZERO_UUID} from "~/utils/uuid"
import {itemExplorationLink} from "~/core/common/input"
import * as routing  from "~/core/routing"
import {memoize} from "lodash"
type InstrumentRefItem = CreatableInstrumentRef

export function renderInstrumentRef(item:InstrumentRef):string {
    if(item.label) return item.label;
    switch(item.type){
        case "name":
        case "ccy":
            return `${item.id}`
            default:
            return "Instrument @" + item.id;
    }
}

export type ApiInstrumentNode = Partial<ApiInstrument> & {
   issuer?:ApiEntityNode
}

export const fragApiInstrumentFields = gql`
fragment ApiInstrumentFields on instruments {
uuid
instrument_type identifier identifier_type
display_name start_date end_date
}
`

interface GetInstrumentOpts {
    offset?:number
    types?:InstrumentType[]
}

/**
 * Get the instruments by name
 * We can optionally srearch by prefix and instrument type
 */
export function getInstruments( prefix:string, opts:GetInstrumentOpts={}):Promise<ApiInstrument[]>{
    let offset = opts.offset ?? 0;
    let params:Record<string,any> ={prefix,offset}
    let body:InstrumentListRequest = {}
    if(opts?.types){
        body.instrument_types=opts.types;
    }
    return DataService.instrumentList(prefix,body,offset);
    //return $axios.$post('/data-types/instruments',body,{params})
}

@Component({
           components:{VChip,VIcon}
})
class InstrumentItem extends Vue {
    @Prop() item!:InstrumentRefItem
    @Prop({default:false}) extended!:boolean

    render(h:CreateElement){
        let {item,extended} = this;
        let children:(VNode|string)[] = [ item.label || `${item.id}` ," " ]
        let chipChildren = [getDetailsType(item) || "???"]

        children.push(h("v-chip", { props:{small:true,label:true,outlined:true}},
                        chipChildren ))
        if(item.isCreateEntry){
            if(extended){
                children.push(h("h6",{},"Create New Security"))
            }else {
                children.push(h("v-chip", { props:{small:true,color:"green",
                    label:true}},
                                "NEW"))

            }
        }

        let body:VNode = h("div",{ class:{"instrument-input-select":true}},children);
        return itemExplorationLink(h,routing.forInstrument(item.id as string) , body);
    }


}

function getDetailsType(item:InstrumentRefItem):string|undefined{
    return item.details?.detail_type
}

let rawObject= Symbol("api-instrument")


function api2Ref(instr:ApiInstrument):InstrumentRef {
    return     {
        id:instr.uuid,
        label:instr.display_name,
        type:InstrumentRefType.ID,
        details:instr.details,
        [rawObject] : instr
    }
}

function isApiInstrument(x:ApiInstrument|InstrumentRef): x is ApiInstrument {
    if(!x) return false;
    if("uuid" in x) return true;
    return false;
}
let pendingClear = false;
const loadInstrument = memoize((id:string) => {
  return InstrumentService.getInstrument(id)
})
function cachedGetInstrument(id:string){
  let p = loadInstrument(id)
  if(!pendingClear){
    pendingClear = true;
    nextTick().then(() => loadInstrument.cache.clear!())
  }
  return p
}

/**
 * Instrument Input component with combobox and autocomplete functionality
 */
@Component({
           inheritAttrs:true,
           components:{InstrumentItem}
})
export default class InstrumentInput extends Vue {
    @ModelSync("value","input") input!:string|ApiInstrument|InstrumentRef|null;
    @Prop({type:[String,Boolean],default:false}) filterType!:false|InstrumentType
    @Prop({type:[String,Number],default: "name"}) outType!:InstrumentRefType
    @Prop({}) vjsfContext!:any; //Optional Vjsf slot context
    @Prop({type:String}) label!:string|null;
    @Prop({type:Array}) errorMessages!:any[]
    @Prop({type:String}) contextId?:string
    @Prop({type:Boolean, default:false}) allowCreate!:boolean
    @Prop({default:false}) createLinkLabel!:string|false;
    @Prop({type:Boolean,default:true}) objectMode!:boolean
    @Prop({type:Boolean,default:false}) refMode!:boolean
    @Inject({from:'inputContext',default:undefined}) inputCxt!:InputContext|undefined

    search:string|null  =  null
    loading:boolean = false
    rawItems:InstrumentRef[] = []
    initValue:InstrumentRef|null  = null
    itemError:Error|any|null = null
    fixed:boolean = false


    get passedListeners():object{
        let {input,...captured} = this.$listeners
        return captured;
    }

    async mounted(){
        let {input} = this
        if(input){
            let updateInitValue = (x:InstrumentRef|null) => {
                this.initValue =  x
                if(this.contextId) this.inputCxt?.write(this.contextId,{
                    id:x,obj:((x as any)?.[rawObject])
                })
            }
            if(typeof(input) !== "string" ) {
                updateInitValue(isApiInstrument(input)?api2Ref(input):input)
            }else if(input != ZERO_UUID){
                //We have a string initial value so we will load the instrument
                cachedGetInstrument(input)
                    .then(instr => {
                        //For An inStrument input we will make a ref  to fill in
                        updateInitValue(api2Ref(instr))
                    })
            }
        }
        if(this.filterType == InstrumentTypes.CCY){
            this.rawItems = (await getCurrencies(this.$axios)).map(api2Ref)
        }
        this.onSearchChange = debounce(this.onSearchChange.bind(this),400) as any
    }

    get displayLabel():string{
        return this.label || this.vjsfContext?.label
    }


    get items():InstrumentRefItem[]{
        let initial:InstrumentRefItem[] = []
        let {allowCreate,input:initValue,search} = this
        if(allowCreate && search){
            search = search.trim();
            if(search.length > 0){
                initial.push({
                             ...genericNamedInstrument(search),
                             isCreateEntry:true
                })
            }
        }

        let found =false;
        const initObj =initValue && typeof (initValue) !== "string"
        //Check to see if the selected value is already in the list
        initial.push(...this.rawItems.map((x:InstrumentRef) => {
            if(initObj && this.compareInstrumentRef(x,initValue!)) found=true;
            return x
        }))
        if(!found){
            if(this.initValue){
                initial.unshift(this.initValue);
            }else if(initObj){
                initial.unshift(initValue as any)
            }
        }
        return initial
    }


    get selectInput():string|null {
        if(!this.input) return null;
        if(typeof(this.input) == "string") return this.input;
        return this.getItemValue(this.input) as string
    }

    onItemSelected(x:InstrumentRef|null){
        if( x == null) {
            this.input = null
            return
        }
        let ret!:any

        if(this.objectMode){
            if(this.refMode){
                ret =x
            }else {
                ret = (x as any)[rawObject] as ApiInstrument
            }
        }else {
            ret = x.id
        }
        this.inputChange(ret,x)
    }
    inputChange(value:any,x:InstrumentRef){
        if(this.contextId) {
            let obj:any = (x as any)?.[rawObject]
            this.inputCxt?.write(this.contextId,{id:value,obj})
        }
        this.input =value;
    }

    @Watch("filterType")
    onFilterTypeChange(){
        this.rawItems = []
    }

    @Watch("search")
    async onSearchChange(input:string){
        if(this.fixed) return ;
        if(input == null || input.trim() == "") return;
        try{
            this.loading=true;
            let opts:GetInstrumentOpts = {}
            if(this.filterType) opts.types = [this.filterType]
            this.rawItems = (await getInstruments(input,opts)).map(api2Ref)
        }catch(err){
            this.rawItems = []
            this.itemError = err

        }finally {
            this.loading =false;
        }
    }

    getItemText(item:ApiInstrument|InstrumentRef|string){
        if(typeof item === "string") return item
        if(isApiInstrument(item)) return item.display_name
        return renderInstrumentRef(item)
    }
    getDetailsType(item:InstrumentRefItem):string|undefined{
        return getDetailsType(item)
    }
    compareInstrumentRef(x:ApiInstrument|InstrumentRefItem|string,y:ApiInstrument|InstrumentRefItem|string):boolean {
        if(x === y ) return true
        if(x == y) return true;
        if(x == null) return false;
        if(y == null) return false;
        return this.getItemValue(x) == this.getItemValue(y)
    }
    getItemValue(x:ApiInstrument|InstrumentRefItem|string){
        if(typeof(x) == "string") return x;
        if(isApiInstrument(x)) {
            if(x.uuid == ZERO_UUID) return null;
            return x.uuid;
        }
        switch(x.type){
            case InstrumentRefType.NAME: return x.id
            case InstrumentRefType.ID: return x.id
            case InstrumentRefType.FIGI:
            case InstrumentRefType.ISIN:
                return [x.id,x.type].join("#")
            default:
                return [x.id,getDetailsType(x)].join("#")
        }
    }


    onReturnPressed(){
    }

//open a window to createa  new entity
    popupCreateInstrument(){
        let instrument_type:string|null = null;
        if(this.filterType) instrument_type = this.filterType;
        let lnk= this.$router.resolve({
                                      name:"instruments-create",query:{
                                          popup:"true",instrument_type
                                      }
        })
        let proxy=window.open(lnk.href,"create-popup");
        if(proxy){
            const listener =(e:CustomEvent) => {
                let created =e.detail["instrument"] as ApiInstrument
                if(created){
                    let elt =api2Ref(created)
                    this.rawItems.unshift(elt)
                    this.onItemSelected(elt);
                }
            }
            proxy.addEventListener("instrument-created" as any ,listener)
        }

    }
}





